import ReduxActions from '../../constants/redux-actions';
import CallerAlias from '../../models/caller-alias';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import Contact from '../../models/contact';
import AcdAttendantGroupAgents from '../../models/acd-attendant-group-agents';
import Agent from '../../models/agent';

function acSetCallerAliases(aliases: CallerAlias[]) {
  return {
    type: ReduxActions.LOAD_ALIASES,
    data: {
      availableAliases: aliases,
      currentAlias: aliases.find(alias => alias.active),
    },
  };
}

function acSetAcdAttendantGroups(groups: AcdAttendantGroup[]) {
  return {
    type: ReduxActions.LOAD_ACD_ATTENDANT_GROUP,
    data: { acdAttendantGroups: groups },
  };
}

function acSetSupervisorGroups(groups: AcdAttendantGroupAgents[]) {
  return {
    type: ReduxActions.LOAD_ACD_ATTENDANT_GROUP_AGENT,
    data: { acdAttendantGroupAgents: groups },
  };
}

function acRefreshAcdAttendantGroups(changedGroup: AcdAttendantGroup) {
  return {
    type: ReduxActions.REFRESH_ACD_ATTENDANT_GROUP,
    data: { changedAcdAttendantGroup: changedGroup },
  };
}

function acRefreshAgent(changedGroup: AcdAttendantGroupAgents, agent: Agent) {
  return {
    type: ReduxActions.REFRESH_AGENT_GROUP,
    data: {
      changedGroup: changedGroup,
      agent: agent,
    },
  };
}

function acSetContactList(contacts: Contact[]) {
  return {
    type: ReduxActions.LOAD_CONTACT_LIST,
    data: { contactList: contacts },
  };
}

function acSetSendSmsErrors(sendSmsErrors: string[]) {
  return {
    type: ReduxActions.SET_SEND_SMS_ERROR,
    data: { sendSmsErrors: sendSmsErrors },
  };
}

export {
  acSetCallerAliases, //
  acSetAcdAttendantGroups,
  acSetSupervisorGroups,
  acRefreshAcdAttendantGroups,
  acRefreshAgent,
  acSetContactList,
  acSetSendSmsErrors,
};
