import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const userDataRecord = (state: any) => state.userData;

const userAliasesSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return {
    availableAliases: record.availableCallerAliases,
    currentAlias: record.currentCallerAlias,
  };
});

const acdAttendantGroupsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.acdAttendantGroups;
});

const supervisorGroupsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.acdAttendantGroupAgents;
});

const contactListSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.contactList;
});

const sendSmsErrorsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.sendSmsErrors;
});

export {
  userAliasesSelector,
  acdAttendantGroupsSelector,
  supervisorGroupsSelector,
  contactListSelector,
  sendSmsErrorsSelector,
};
