enum ErrorCode {
  NONE,
  UNKNOWN,
  INVALID_CREDENTIALS,
  CREDS_ALREADY_IN_USE,
  USER_NOT_FOUND,
  DATA_NOT_FOUND,
  UPDATE_FAIL,
  DELETE_FAIL,
  INVALID_DATA,
  MS_TEAMS_DATA_NOT_FOUND,
}

export default ErrorCode;
