import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';

const defaultState = {
  authData: {},
  isAuthenticated: null,
  error: null,
};

const authenticate = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.AUTHENTICATE:
      return {
        ...state,
        authData: action.data.isOk,
        error: action.data.error,
        isAuthenticated: action.data.isAuthenticated,
      };
    case ReduxActions.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.data,
      };
    default:
      return state;
  }
};

export default authenticate;
