import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const settingsDataRecord = (state: any) => state.settingsData;

const hasMsGraphAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasMsGraphAccess;
});

const hasSearchContactsAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasSearchContactsAccess;
});

const addPermissionsUrlSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.addPermissionsUrl;
});

const addSearchPermissionsUrlSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.addSearchPermissionsUrl;
});

const userEmailSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.userEmail;
});

const isGlobalAdminSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.isGlobalAdmin;
});

const isPbxAdminSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.isPbxAdmin;
});

const hasSmsWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasSmsWidgetAccess;
});

const hasCallRecordingWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasCallRecordingWidgetAccess;
});

const hasCallTransferWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasCallTransferWidgetAccess;
});

const presenceActivitiesSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.presenceActivities;
});

const userPresenceMappingsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.userPresenceMappings;
});

const userPresenceDefaultMappingsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.userPresenceDefaultMappings;
});

export {
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  addPermissionsUrlSelector,
  addSearchPermissionsUrlSelector,
  userEmailSelector,
  presenceActivitiesSelector,
  userPresenceMappingsSelector,
  isGlobalAdminSelector,
  isPbxAdminSelector,
  hasSmsWidgetAccessSelector,
  hasCallTransferWidgetAccessSelector,
  hasCallRecordingWidgetAccessSelector,
  userPresenceDefaultMappingsSelector,
};
