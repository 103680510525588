enum DeviceType {
  MOBILE, //
  TABLET,
  SOFTPHONE,
  DESKPHONE,
  PBX,
  DECT,
  THIRD_PARTY,
}
export default DeviceType;
