import CallAction from './call-action';
import RemoteCallParty from './remote-party';

class CallState {
  stateToken: number;
  callId: string;
  recording: boolean;
  externalRecording: boolean;
  externalRecordingPaused: boolean;
  remoteParty: RemoteCallParty;
  deviceIds: string[];
  actions: CallAction[];

  constructor(
    stateToken: number,
    callId: string,
    recording: boolean,
    externalRecording: boolean,
    externalRecordingPaused: boolean,
    remoteParty: RemoteCallParty,
    deviceIds: string[],
    actions: CallAction[]
  ) {
    this.stateToken = stateToken;
    this.callId = callId;
    this.recording = recording;
    this.externalRecording = externalRecording;
    this.externalRecordingPaused = externalRecordingPaused;
    this.remoteParty = remoteParty;
    this.deviceIds = deviceIds;
    this.actions = actions;
  }

  static hasAction(call: CallState, action: string): boolean {
    return call.actions.findIndex((callAction: CallAction) => callAction.action === action) !== -1;
  }
}

export default CallState;
