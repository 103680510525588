import React from 'react';
import { Button, CallControlCloseTrayIcon } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { authentication } from '../../i18n/all';
import * as authenticateActions from '../../store/actions/authenticate-action';
import { userEmailSelector } from '../../store/selectors/settings-data-selectors';
import * as settingsDataActions from '../../store/actions/settings-data-action';
import { isMobile } from 'react-device-detect';

interface AuthenticationProps {
  msTeamsContext: microsoftTeams.Context | null;
  userEmail: string;
  actions: any;
}

interface AuthenticationState {}

export class Authentication extends React.Component<AuthenticationProps, AuthenticationState> {
  render() {
    return (
      <div>
        <div style={{ display: 'inline', paddingRight: '10px' }}>
          {authentication.loggedInUser} <b>{this.props.userEmail}</b>
        </div>
        <Button
          style={{ display: 'inline', marginTop: isMobile ? '5px' : '0' }}
          id="logout"
          onClick={() => {
            this.props.actions.removeAuthentication(this.props.msTeamsContext?.userObjectId, () =>
              this.props.actions.loadPermissions(
                this.props.msTeamsContext?.userObjectId,
                this.props.msTeamsContext?.tid
              )
            );
          }}
        >
          <CallControlCloseTrayIcon styles={{ marginRight: '10px' }} />
          {authentication.logout}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  userEmail: userEmailSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authenticateActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
