import {
  getCallerAliases,
  getAcdAttendantGroup,
  getSupervisorGroup,
  updateAlias,
  getContactList,
  postLoginUserToAcdAttendantGroup,
  sendSms,
  postSupervisorLoginUserToAcdAttendantGroup,
} from '../../api/userApi';
import * as actionCreators from '../action-creators/user-data-action-creators';
import * as commonActionCreators from '../action-creators/common-action-creators';
import httpStatusCodes from '../../constants/http-status-codes';
import { BaseAction } from '../model/action';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import CallerAlias from './../../models/caller-alias';
import Agent from '../../models/agent';
import AcdAttendantGroupAgents from './../../models/acd-attendant-group-agents';

const loadCallerAliases = (userObjectId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getCallerAliases(userObjectId);
    dispatch(actionCreators.acSetCallerAliases(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const updateCallerAliases = (userObjectId: string, aliases: CallerAlias[]) => async (
  dispatch: (action: BaseAction) => void
) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const selectedAlias = aliases.find(alias => alias.active);
    const data = {
      userId: userObjectId,
      type: selectedAlias?.type,
      groupId: selectedAlias?.groupId,
    };
    const response = await updateAlias(data);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCallerAliases(aliases));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadAcdAttendantGroup = (userObjectId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getAcdAttendantGroup(userObjectId);
    dispatch(actionCreators.acSetAcdAttendantGroups(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadSupervisorGroup = (userObjectId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getSupervisorGroup(userObjectId);
    dispatch(actionCreators.acSetSupervisorGroups(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loginUserToAcdAttendantGroup = (userObjectId: string, group: AcdAttendantGroup) => async (
  dispatch: (action: BaseAction) => void
) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await postLoginUserToAcdAttendantGroup(userObjectId, group);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acRefreshAcdAttendantGroups(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const supervisorLoginUserToAcdAttendantGroup = (
  userObjectId: string,
  group: AcdAttendantGroupAgents,
  agent: Agent
) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await postSupervisorLoginUserToAcdAttendantGroup(userObjectId, group, agent);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acRefreshAgent(group, response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadContactList = (userObjectId: string, query: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    const response = await getContactList(userObjectId, query);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetContactList(response.data.data));
    }
  } catch (error) {
    console.error(error);
  }
};

const sendSmsMessage = (
  userObjectId: string,
  text: string,
  numbers: string[],
  callerAlias: CallerAlias,
  callback?: (result: boolean) => {}
) => async (dispatch: (action: BaseAction) => void) => {
  if (!text || !numbers?.length || !callerAlias) {
    if (callback) {
      callback(false);
    }
    return;
  }
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await sendSms(userObjectId, text, numbers, callerAlias);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetSendSmsErrors(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (callback) {
      callback(true);
    }
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export {
  loadCallerAliases, //
  updateCallerAliases,
  loadAcdAttendantGroup,
  loadSupervisorGroup,
  loginUserToAcdAttendantGroup,
  supervisorLoginUserToAcdAttendantGroup,
  loadContactList,
  sendSmsMessage,
};
