import React from 'react';
import { Dropdown, Flex, Header } from '@fluentui/react-northstar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tabPageSelector } from '../../i18n/all';

interface TabPageSelectorProps {
  onSelect: (selectedPage: string) => void;
  actions: any;
}

interface TabPageSelectorState {
  selectedIndex: number;
}

const tabPages = [
  { key: 'dashboard' }, //
  { key: 'test' },
];

export class TabPageSelector extends React.Component<TabPageSelectorProps, TabPageSelectorState> {
  constructor(props: TabPageSelectorProps) {
    super(props);

    this.state = {
      selectedIndex: -1,
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(event: any, data: any) {
    if (data.highlightedIndex === -1) {
      return;
    }

    const index = data.highlightedIndex;
    this.setState(
      {
        selectedIndex: index === -1 ? data.defaultActiveSelectedIndex : index,
      },
      () => this.props.onSelect(tabPages[this.state.selectedIndex].key)
    );
  }

  render() {
    return (
      <Flex column>
        <Header as="h3" content={tabPageSelector.chooseTabPage} />
        <Dropdown
          fluid
          value={
            this.state.selectedIndex === -1
              ? tabPageSelector.selectTabPage
              : tabPageSelector.getString(tabPages[this.state.selectedIndex].key)
          }
          items={tabPages?.map(c => ({
            key: c.key,
            header: tabPageSelector.getString(c.key),
          }))}
          itemToValue={(item: any) => item?.key}
          onChange={this.onSelect}
        />
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  // TBD
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      // TBD
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabPageSelector);
