import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';
import AcdAttendantGroup from './../../models/acd-attendant-group';
import Contact from '../../models/contact';
import AcdAttendantGroupAgents from '../../models/acd-attendant-group-agents';

const defaultState = {
  availableCallerAliases: null,
  currentCallerAlias: null,
  acdAttendantGroups: Array<AcdAttendantGroup>(),
  acdAttendantGroupAgents: null,
  contactList: Array<Contact>(),
  sendSmsErrors: Array<string>(),
};

const userData = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.LOAD_ALIASES: {
      return {
        ...state,
        availableCallerAliases: action.data.availableAliases,
        currentCallerAlias: action.data.currentAlias,
      };
    }
    case ReduxActions.LOAD_ACD_ATTENDANT_GROUP: {
      return {
        ...state,
        acdAttendantGroups: action.data.acdAttendantGroups,
      };
    }
    case ReduxActions.LOAD_ACD_ATTENDANT_GROUP_AGENT: {
      return {
        ...state,
        acdAttendantGroupAgents: action.data.acdAttendantGroupAgents,
      };
    }
    case ReduxActions.REFRESH_ACD_ATTENDANT_GROUP: {
      const newState = {
        acdAttendantGroups: [...state.acdAttendantGroups],
      };
      let newCollection = newState.acdAttendantGroups;
      var index = newCollection.findIndex(group => group.id === action.data.changedAcdAttendantGroup.id);
      newCollection[index] = action.data.changedAcdAttendantGroup;
      return {
        ...state,
        ...newState,
      };
    }
    case ReduxActions.REFRESH_AGENT_GROUP: {
      const newState = {
        acdAttendantGroupAgents: [...(state.acdAttendantGroupAgents || Array<AcdAttendantGroupAgents>())],
      };
      let newCollection = newState.acdAttendantGroupAgents;
      var groupIndex = newCollection.findIndex(group => group.id === action.data.changedGroup.id);
      if (groupIndex < 0) {
        return { ...state };
      }
      var agentIndex = newCollection[groupIndex].agents.findIndex(agent => agent.id === action.data.agent.id);
      if (agentIndex < 0) {
        return { ...state };
      }
      newCollection[groupIndex].agents[agentIndex] = action.data.agent;
      return {
        ...state,
        ...newState,
      };
    }
    case ReduxActions.LOAD_CONTACT_LIST: {
      return {
        ...state,
        contactList: action.data.contactList,
      };
    }
    case ReduxActions.SET_SEND_SMS_ERROR: {
      return {
        ...state,
        sendSmsErrors: action.data.sendSmsErrors,
      };
    }
    case ReduxActions.RESET_STATE:
      return { ...state, ...defaultState };

    default:
      return state;
  }
};

export default userData;
