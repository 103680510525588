import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const deviceDataRecord = (state: any) => {
  return state.device;
};

const devicesSelector = createSelector(deviceDataRecord, (record = EMPTY_OBJECT) => {
  return record.devices;
});

export { devicesSelector };
