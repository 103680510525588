const hostname = document.location ? document.location.hostname : '';
const endpoints = {
  backEndBase: 'https://5b25-178-95-72-25.eu.ngrok.io/api',
};

switch (hostname) {
  case 'teamsui.soluno.se':
    endpoints.backEndBase = 'https://teams.soluno.se/api';
    break;

  case 'teamsui.soluno.com':
    endpoints.backEndBase = 'https://teams.soluno.com/api';
    break;

  case 'teamsui.pbxaddons.com':
    endpoints.backEndBase = 'https://teams.pbxaddons.com/api';
    break;

  case 'teamsstagingui.pbxaddons.com':
    endpoints.backEndBase = 'https://teamsstaging.pbxaddons.com/api';
    break;

  case 'tmobileui.pbxaddons.com':
    endpoints.backEndBase = 'https://tmobileapi.pbxaddons.com/api';
    break;

  case 'localhost':
    endpoints.backEndBase = 'https://5b25-178-95-72-25.eu.ngrok.io/api';
    break;

  default:
    endpoints.backEndBase = 'https://teamsstaging.pbxaddons.com/api';
    break;
}

export default endpoints;
