import React from 'react';
import { Header, Flex, Button, Text, SyncIcon } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import {
  addPermissionsUrlSelector,
  addSearchPermissionsUrlSelector,
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  isGlobalAdminSelector,
} from '../../store/selectors/settings-data-selectors';
import { grantPermission } from '../../i18n/all';
import * as settingsDataActions from '../../store/actions/settings-data-action';

interface MsGrantPermissionsProps {
  msTeamsContext: microsoftTeams.Context | null;
  addPermissionsUrl: string;
  addSearchPermissionsUrl: string;
  hasMsGraphAccess: boolean;
  hasSearchContactsAccess: boolean;
  isGlobalAdmin: boolean;
  actions: any;
}

interface MsGrantPermissionsState {}

export class MsGrantPermissions extends React.Component<MsGrantPermissionsProps, MsGrantPermissionsState> {
  render() {
    const start = grantPermission.getPermission.indexOf('[');
    const end = grantPermission.getPermission.indexOf(']');
    const startU = grantPermission.getUserPermission.indexOf('[');
    const endU = grantPermission.getUserPermission.indexOf(']');

    return (
      <Flex column gap="gap.small">
        <Flex space="between" vAlign="end">
          <Header as="h3" content={grantPermission.header}></Header>
          <Button
            id="refreshPermissions"
            onClick={() => {
              this.props.actions.loadPermissions(
                this.props.msTeamsContext?.userObjectId,
                this.props.msTeamsContext?.tid
              );
            }}
          >
            <SyncIcon />
          </Button>
        </Flex>
        {!!this.props.isGlobalAdmin && !!this.props.hasMsGraphAccess && (
          <Text id="grantedInfo">{grantPermission.permissionsGranted}</Text>
        )}
        {(this.props.hasMsGraphAccess === false || this.props.hasSearchContactsAccess === false) && (
          <div>
            {this.props.hasMsGraphAccess === false && (
              <div>
                <Text>{grantPermission.getPermission.substr(0, start)}&nbsp;</Text>
                {/* eslint-disable-next-line */}
                <a href={this.props.addPermissionsUrl} target="_blank" id="grandPermissionUrl">
                  {grantPermission.getPermission.substr(start + 1, end - 1 - start)}
                </a>
                <Text>&nbsp;{grantPermission.getPermission.substr(end + 1)}</Text>
              </div>
            )}
            {this.props.hasSearchContactsAccess === false && (
              <div>
                <Text>{grantPermission.getUserPermission.substr(0, startU)}&nbsp;</Text>
                {/* eslint-disable-next-line */}
                <a href={this.props.addSearchPermissionsUrl} target="_blank" id="grandUserPermissionUrl">
                  {grantPermission.getUserPermission.substr(startU + 1, endU - 1 - startU)}
                </a>
                <Text>&nbsp;{grantPermission.getUserPermission.substr(endU + 1)}</Text>
              </div>
            )}
          </div>
        )}
        {!!this.props.hasSearchContactsAccess && (
          <Text id="grantedUserInfo">{grantPermission.permissionsSearchGranted}</Text>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  addPermissionsUrl: addPermissionsUrlSelector(state),
  addSearchPermissionsUrl: addSearchPermissionsUrlSelector(state),
  hasMsGraphAccess: hasMsGraphAccessSelector(state),
  hasSearchContactsAccess: hasSearchContactsAccessSelector(state),
  isGlobalAdmin: isGlobalAdminSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MsGrantPermissions);
