import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';
import ResponseModel from '../../models/response-model';

function acAuthenticate(response: ResponseModel): BaseAction {
  return {
    type: ReduxActions.AUTHENTICATE,
    data: {
      isOk: !!response.data,
      error: response.errorCode,
      isAuthenticated: !response.isError && !!response.data,
    },
  };
}

function acSetIsAuthenticated(isAuthenticated: boolean): BaseAction {
  return {
    type: ReduxActions.SET_IS_AUTHENTICATED,
    data: isAuthenticated,
  };
}

export {
  acAuthenticate, //
  acSetIsAuthenticated,
};
