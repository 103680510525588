// IMPORTANT FOR DEVS!!
// Duplicate translations also in en.json to add them in weblate.soluno.se
import LocalizedStrings from 'react-localization';

const loginForm = new LocalizedStrings({
  en: {
    "enterYourEmail": "Enter your email:",
    "enterYourPassword": "Enter your password:",
    "NONE": "Undefined error",
    "UNKNOWN": "Unknown error",
    "INVALID_CREDENTIALS": "Invalid credentials",
    "CREDS_ALREADY_IN_USE": "Credentials are already in use",
    "USER_NOT_FOUND": "User not found",
    "DATA_NOT_FOUND": "Data not found",
    "UPDATE_FAIL": "Failed to update",
    "DELETE_FAIL": "Failed to delete",
    "INVALID_DATA": "Invalid data",
    "MS_TEAMS_DATA_NOT_FOUND": "We couldn't find your Teams data. Please, reinstall application",
  },
  sv: {
    "enterYourEmail": "Ange e-postadress:",
    "enterYourPassword": "Ange lösenord:",
    "NONE": "Odefinierat fel",
    "UNKNOWN": "Okänt fel",
    "INVALID_CREDENTIALS": "Ogiltiga uppgifter",
    "CREDS_ALREADY_IN_USE": "Uppgifterna används redan",
    "USER_NOT_FOUND": "Användaren hittades inte",
    "DATA_NOT_FOUND": "Data hittades inte",
    "UPDATE_FAIL": "Det gick inte att uppdatera",
    "DELETE_FAIL": "Det gick inte att ta bort",
    "INVALID_DATA": "Ogiltiga data",
    "MS_TEAMS_DATA_NOT_FOUND": "Vi kunde inte hitta dina Teamsdata. Var vänlig installera om programmet",
  },
  nl: {
    "enterYourEmail": "Voer uw e-mailadres in:",
    "enterYourPassword": "Voer uw wachtwoord in:",
    "NONE": "Ongedefinieerde fout",
    "UNKNOWN": "Onbekende fout",
    "INVALID_CREDENTIALS": "Ongeldige inloggegevens",
    "CREDS_ALREADY_IN_USE": "Inloggegevens zijn al in gebruik",
    "USER_NOT_FOUND": "Gebruiker niet gevonden",
    "DATA_NOT_FOUND": "Data niet gevonden",
    "UPDATE_FAIL": "Kan niet worden bijgewerkt",
    "DELETE_FAIL": "Kon niet worden verwijderd",
    "INVALID_DATA": "Onjuiste data",
    "MS_TEAMS_DATA_NOT_FOUND": "We kunnen uw Teams-gegevens niet vinden. Installeer de applicatie opnieuw",
  },
  de: {
    "enterYourEmail": "Geben sie ihre E-Mail Adresse ein:",
    "enterYourPassword": "Geben Sie Ihr Passwort ein:",
    "NONE": "Undefinierter Fehler",
    "UNKNOWN": "Unbekannter Fehler",
    "INVALID_CREDENTIALS": "Ungültige Anmeldeinformationen",
    "CREDS_ALREADY_IN_USE": "Anmeldeinformationen werden bereits verwendet",
    "USER_NOT_FOUND": "Benutzer nicht gefunden",
    "DATA_NOT_FOUND": "Daten nicht gefunden",
    "UPDATE_FAIL": "Aktualisierung fehlgeschlagen",
    "DELETE_FAIL": "Fehler beim Löschen",
    "INVALID_DATA": "Ungültige Daten",
    "MS_TEAMS_DATA_NOT_FOUND": "Wir konnten Ihre Teamdaten nicht finden. Bitte installieren Sie die Anwendung neu",
  },
});
const tabPageSelector = new LocalizedStrings({
  en: {
    "chooseTabPage": "Choose tab page:",
    "selectTabPage": "Select tab page",
    "dashboard": "Dashboard",
    "test": "test",
  },
  sv: {
    "chooseTabPage": "Välj flik:",
    "selectTabPage": "Välj flik:",
    "dashboard": "Översikt",
    "test": "test",
  },
  nl: {
    "chooseTabPage": "Kies een tabblad:",
    "selectTabPage": "Selecteer een tabblad",
    "dashboard": "Dashboard",
    "test": "test",
  },
  de: {
    "chooseTabPage": "Registerkarte auswählen:",
    "selectTabPage": "Registerkarte auswählen",
    "dashboard": "Dashboard",
    "test": "test",
  },
});
const acdAttendantGroups = new LocalizedStrings({
  en: {
    "name": "Name:",
    "loggedInAgents": "Logged in Agents",
    "isLoggedIn": "Log out/in",
    "listOfGroups": "Groups",
  },
  sv: {
    "name": "Namn:",
    "loggedInAgents": "Inloggade agenter",
    "isLoggedIn": "Logga ut/in",
    "listOfGroups": "Grupper",
  },
  nl: {
    "name": "Naam:",
    "loggedInAgents": "Ingelogde agenten",
    "isLoggedIn": "Uitloggen/Log in",
    "listOfGroups": "Groepen",
  },
  de: {
    "name": "Name:",
    "loggedInAgents": "Angemeldete Agenten",
    "isLoggedIn": "Aus/Einloggen",
    "listOfGroups": "Gruppen",
  },
});
const supervisorGroups = new LocalizedStrings({
  en: {
    "name": "Log in/out colleagues of groups",
    "emptyPlaceholder": "You have no groups where you have supervisor role",
    "group": "Group",
  },
  sv: {
    "name": "Logga in / ut kollegor i grupper",
    "emptyPlaceholder": "Du har inga grupper där du har chefsroll",
    "group": "Grupp",
  },
  nl: {
    "name": "Log in / uit collegas van groepen",
    "emptyPlaceholder": "Je hebt geen groepen waarin je de rol van supervisor hebt",
    "group": "Groep",
  },
  de: {
    "name": "Anmelden / Abmelden von Kollegen von Gruppen",
    "emptyPlaceholder": "Sie haben keine Gruppen, in denen Sie eine Supervisor-Rolle haben",
    "group": "Gruppe",
  },
});
const sendSms = new LocalizedStrings({
  en: {
    "sendSms": "Send SMS",
    "searchPlaceholder": "Search contacts (type min 3 letters)",
    "searchNoResults": "We could not find any matches.",
    "typeNumberPlaceholder": "Add recipients manually (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Select Caller ID",
    "messagePlaceholder": "Type your message here...",
    "messageIsSent": "Message is sent",
    "messageIsNotSent": "Message is not sent",
    "errorMessage": "Can not deliver the message to the recipients:",
    "updating": "Updating...",
  },
  sv: {
    "sendSms": "Skicka SMS",
    "searchPlaceholder": "Sök kontakter (skriv minst 3 tecken)",
    "searchNoResults": "Vi kunde inte hitta några träffar.",
    "typeNumberPlaceholder": "Lägg till mottagare manuellt (ex .: +123456789012; +09875432109)",
    "selectCallerIdPlaceholder": "Välj avsändarnummer.",
    "messagePlaceholder": "Skriv ditt meddelande här...",
    "messageIsSent": "Meddelandet skickat",
    "messageIsNotSent": "Meddelandet skickades inte",
    "errorMessage": "Kan inte leverera meddelandet till mottagarna:",
    "updating": "Uppdaterar...",
  },
  nl: {
    "sendSms": "Verstuur SMS",
    "searchPlaceholder": "Zoek contacten (typ minimaal 3 letters)",
    "searchNoResults": "Kan geen overeenkomsten vinden.",
    "typeNumberPlaceholder": "Handmatig ontvangers toevoegen (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Selecteer nummerherkenning",
    "messagePlaceholder": "Schrijf je bericht hier...",
    "messageIsSent": "Bericht is verzonden",
    "messageIsNotSent": "Bericht is niet verzonden",
    "errorMessage": "Kan het bericht niet afleveren bij de ontvangers:",
    "updating": "Updaten...",
  },
  de: {
    "sendSms": "SMS senden",
    "searchPlaceholder": "Kontakte durchsuchen (Geben Sie mindestens 3 Buchstaben ein)",
    "searchNoResults": "Wir konnten keine Übereinstimmungen finden.",
    "typeNumberPlaceholder": "Empfänger manuell hinzufügen (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Wählen Sie Anrufer-ID",
    "messagePlaceholder": "Schreiben Sie ihre Nachricht hier...",
    "messageIsSent": "Nachricht wird gesendet",
    "messageIsNotSent": "Nachricht wird nicht gesendet",
    "errorMessage": "Kan het bericht niet afleveren bij de ontvangers:",
    "updating": "Updaten...",
  },
});
const setCallerId = new LocalizedStrings({
  en: {
    "setYourCallerId": "Select you caller id",
  },
  sv: {
    "setYourCallerId": "Välj avsändarnummer",
  },
  nl: {
    "setYourCallerId": "Selecteer nummerherkenning",
  },
  de: {
    "setYourCallerId": "Wählen Sie Ihre Anrufer-ID",
  },
});
const voiceMail = new LocalizedStrings({
  en: {
    "caller": "Caller",
    "record": "Record",
    "time": "Time",
    "seen": "Seen",
    "played": "Played",
    "listHeader": "Voicemails",
    "noRecordsText": "You have no voicemails",
    "deleteConfirmation1": "Are you sure you want to delete",
    "deleteConfirmation2": "item(s)?",
    "popupHeader": "Confirm delete?",
    "deleteSelected": "Delete selected",
    "showItems": "Show items:",
    "of": "of",
    "errorDeleting": "Error deleting item(s):",
    "voiceMailLoadError": "Failed to load voicemail",
    "cancel": "Cancel",
    "confirm": "Confirm",
  },
  sv: {
    "caller": "Uppringare",
    "record": "Spela in",
    "time": "Tid",
    "seen": "Sett",
    "played": "Lyssnat",
    "listHeader": "Röstmeddelanden",
    "noRecordsText": "Inga röstmeddelanden",
    "deleteConfirmation1": "Är du säker på att du vill ta bort ",
    "deleteConfirmation2": " objekt?",
    "popupHeader": "Bekräfta borttagning",
    "deleteSelected": "Radera valda",
    "showItems": "Visa artiklar:",
    "of": "av",
    "errorDeleting": "Fel vid radering av objekt:",
    "voiceMailLoadError": "Det gick inte att ladda r?stmeddelandet",
    "cancel": "Annullera",
    "confirm": "Bekräfta",
  },
  nl: {
    "caller": "Beller",
    "record": "Vermelding",
    "time": "Tijd",
    "seen": "Gezien",
    "played": "Gespeeld",
    "listHeader": "Voicemails",
    "noRecordsText": "U heeft geen voicemails",
    "deleteConfirmation1": "Weet je zeker dat je wilt verwijderen",
    "deleteConfirmation2": "Artikelen?",
    "popupHeader": "Bevestig verwijderen?",
    "deleteSelected": "Verwijder geselecteerde",
    "showItems": "Toon items:",
    "of": "van",
    "errorDeleting": "Fout bij het verwijderen van items:",
    "voiceMailLoadError": "kon voicemail niet laden",
    "cancel": "Annuleren",
    "confirm": "Bevestigen",
  },
  de: {
    "caller": "Anruferin",
    "record": "Aufzeichnung",
    "time": "Zeit",
    "seen": "Gesehen",
    "played": "Gespielt",
    "listHeader": "Voicemails",
    "noRecordsText": "Sie haben keine Voicemails",
    "deleteConfirmation1": "Sind Sie sicher, dass Sie löschen möchten",
    "deleteConfirmation2": "Artikel?",
    "popupHeader": "Löschen bestätigen?",
    "deleteSelected": "Ausgewählte löschen",
    "showItems": "Elemente anzeigen:",
    "of": "von",
    "errorDeleting": "Fehler beim Löschen von Elementen:",
    "voiceMailLoadError": "Voicemail konnte nicht geladen werden",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
  },
});
const dashboard = new LocalizedStrings({
  en: {
    "login": "Login",
    "deprecationNotice": "You're using our outdated application within Teams. Please install our latest application. If you encounter any issues downloading or installing it, don't hesitate to contact us for help or follow the instructions in the link below.",
    "foundHere": "Installations guide Teams Connect app"
  },
  sv: {
    "login": "Logga in",
    "deprecationNotice": "Du använder vår föråldrade applikation för Teams. Vänligen installera vår senaste applikation. Om du stöter på problem med att ladda ner eller installera den, tveka inte att kontakta oss för hjälp eller följ instruktionerna i länken nedan.",
    "foundHere": "Installationsguide för Teams Connect-appen"
  },
  nl: {
    "login": "Log in",
    "deprecationNotice": "U gebruikt onze verouderde applicatie binnen Teams. Installeer alstublieft onze nieuwste applicatie. Als u problemen ondervindt bij het downloaden of installeren ervan, aarzel dan niet om contact met ons op te nemen voor hulp of volg de instructies in de onderstaande link.",
    "foundHere": "Installatiehandleiding Teams Connect-app"
  },
  de: {
    "login": "Einloggen",
    "deprecationNotice": "Sie verwenden unsere veraltete Anwendung innerhalb von Teams. Bitte installieren Sie unsere neueste Anwendung. Wenn Sie Probleme beim Herunterladen oder Installieren haben, zögern Sie nicht, uns für Hilfe zu kontaktieren oder folgen Sie den Anweisungen im folgenden Link.",
    "foundHere": "Installationsanleitung für die Teams Connect-App"
  },
});
const settings = new LocalizedStrings({
  en: {
    "mappingSettings": "Presence mapping",
    "defaultMappingSettings": "Presence default mapping",
    "msTeamsManualPresence": "Teams Manual Presences",
    "msTeamsAutoPresence": "Teams Automatic Presences",
    msTeamsAutoPresenceHint:
      'Teams sets these presences based on app usage. Only map these if comfortable with Teams behaviour',
    "pbxPresence": "Phone system presences",
    "save": "Save",
    "discard": "Discard",
    "Available": "Available",
    "Away": "Appear away",
    "BeRightBack": "Be right back",
    "Busy": "Busy",
    "DoNotDisturb": "Do not disturb",
    "InACall": "In a call",
    "InAConferenceCall": "In a conference call",
    "Inactive": "Inactive",
    "InAMeeting": "In a meeting",
    "Offline":  "Off work",
    "OffWork": "Appear offline",
    "OutOfOffice": "Out of office",
    "PresenceUnknown": "Presence unknown",
    "Presenting": "Presenting",
    "UrgentInterruptionsOnly": "Urgent interruptions only",
    "resetToDefault": "Reset to default", 
  },
  sv: {
    "mappingSettings": "Närvarokartläggning",
    "defaultMappingSettings": "Standardmappning för närvaro",
    "msTeamsManualPresence": "Teams manuella status",
    "msTeamsAutoPresence": "Teams automatiska närvaro",
    "msTeamsAutoPresenceHint":
      'Team ställer in dessa närvaro baserat på appanvändning. Kartlägg endast dessa om de är bekväma med Teams beteende',
    "pbxPresence": "Motsvarande status i telefonsystemet",
    "save": "Spara",
    "discard": "Kassera",
    "Available": "Tillgänglig",
    "Away": "Borta",
    "BeRightBack": "Strax tillbaka",
    "Busy": "Upptagen",
    "DoNotDisturb": "Stör ej",
    "InACall": "Upptagen i samtal",
    "InAConferenceCall": "Upptagen i konferenssamtal",
    "Inactive": "Inaktiv",
    "InAMeeting": "I ett möte",
    "Offline": "Från Jobbet",
    "OffWork": "Off-line",
    "OutOfOffice": "Inte på kontoret",
    "PresenceUnknown": "Närvaro okänd",
    "Presenting": "Presenterar",
    "UrgentInterruptionsOnly": "Endast brådskande avbrott",
    "resetToDefault": "Återställ till standard",
  },
  nl: {
    "mappingSettings": "Aanwezigheid in kaart brengen",
    "defaultMappingSettings": "Aanwezigheid standaard mapping",
    "msTeamsManualPresence": "Teams handmatige aanwezigheid",
    "msTeamsAutoPresence": "Automatische aanwezigheid van teams",
    "msTeamsAutoPresenceHint":
      'Teams stelt deze aanwezigheid in op basis van app-gebruik. Breng deze alleen in kaart als u vertrouwd bent met het gedrag van Teams',
    "pbxPresence": "Aanwezigheid telefoonsysteem",
    "save": "Opslaan",
    "discard": "Weggooien",
    "Available": "Beschikbaar",
    "Away": "Als afwezig weergeven",
    "BeRightBack": "Zo terug",
    "Busy": "Bezet",
    "DoNotDisturb": "Niet storen",
    "InACall": "In een gesprek",
    "InAConferenceCall": "In een telefonische vergadering",
    "Inactive": "Inactief",
    "InAMeeting": "In een vergadering",
    "Offline": "Buiten het werk",
    "OffWork": "Offline weergeven", 
    "OutOfOffice": "Niet op kantoor",
    "PresenceUnknown": "Aanwezigheid onbekend",
    "Presenting": "Presenteren",
    "UrgentInterruptionsOnly": "Alleen urgente onderbrekingen",
    "resetToDefault": "Reset naar standaard",
  },
  de: {
    "mappingSettings": "Anwesenheitszuordnung",
    "defaultMappingSettings": "Standardzuordnung vorhanden",
    "msTeamsManualPresence": "Manuelle Anwesenheit des Teams",
    "msTeamsAutoPresence": "Teams Automatische Präsenz",
    "msTeamsAutoPresenceHint":
      'Teams legen diese Einstellungen basierend auf der App-Nutzung fest. Ordnen Sie diese nur zu, wenn Sie mit dem Verhalten des Teams vertraut sind',
    "pbxPresence": "Anwesenheit des Telefonsystems",
    "save": "Speichern",
    "discard": "Verwerfen",
    "Available": "Verfügbar",
    "Away": "Weg",
    "BeRightBack": "Ich komme gleich wieder",
    "Busy": "Beschäftigt",
    "DoNotDisturb": "Nicht stören",
    "InACall": "In einem Telefongesprach",
    "InAConferenceCall": "In einer Telefonkonferenz",
    "Inactive": "Inaktiv",
    "InAMeeting": "In einem Meeting",
    "Offline": "Off work",
    "OffWork": "Offline",
    "OutOfOffice": "Ausserhaus",
    "PresenceUnknown": "Anwesenheit unbekannt",
    "Presenting": "Präsentieren",
    "UrgentInterruptionsOnly": "Nur dringende Unterbrechungen",
    "resetToDefault": "Zurücksetzen",
  },
});
const grantPermission = new LocalizedStrings({
  en: {
    "header": "MS Teams permissions",
    "getPermission": "With an administrator account, you can  [grant permissions] to all other users in tenant",
    "permissionsGranted": "Application permissions were granted successfully",
    "getUserPermission": "To search in Outlook contacts, you can [grant permissions] for your user",
    "permissionsSearchGranted": "Permissions to search outlook contacts were granted successfully",
  },
  sv: {
    "header": "MS Teams behörigheter",
    "getPermission": "Med ett administratörskonto kan du [bevilja behörigheter] till alla andra användare i klienten",
    "permissionsGranted": "Begärda behörigheter beviljades framgångsrikt",
    "getUserPermission": "För att söka i Outlook-kontakter kan du [bevilja behörigheter] för din användare",
    "permissionsSearchGranted": "Behörigheter att söka Outlook-kontakter beviljades framgångsrikt",
  },
  nl: {
    "header": "MS Teams-machtigingen",
    "getPermission": "Met een beheerdersaccount kunt u [machtigingen verlenen] aan alle andere gebruikers in de tenant",
    "permissionsGranted": "Toepassingsrechten zijn met succes verleend",
    "getUserPermission": "Om in Outlook-contacten te zoeken, kunt u [machtigingen verlenen] voor uw gebruiker",
    "permissionsSearchGranted": "Toestemmingen om Outlook-contacten te zoeken zijn met succes verleend",
  },
  de: {
    "header": "MS Teams-Berechtigungen",
    "getPermission": "Mit einem Administratorkonto können Sie allen anderen Benutzern im Mandanten [Berechtigungen erteilen]",
    "permissionsGranted": "Anwendungsberechtigungen wurden erfolgreich erteilt",
    "getUserPermission": "Um in Outlook-Kontakten zu suchen, können Sie Ihrem Benutzer [Berechtigungen erteilen]",
    "permissionsSearchGranted": "Berechtigungen zum Durchsuchen von Outlook-Kontakten wurden erfolgreich erteilt",
  },
});
const authentication = new LocalizedStrings({
  en: {
    "logout": "Log out",
    "loggedInUser": "You are currently logged in as ",
  },
  sv: {
    "logout": "Logga ut",
    "loggedInUser": "Du är för närvarande inloggad som ",
  },
  nl: {
    "logout": "Uitloggen",
    "loggedInUser": "U bent momenteel aangemeld als ",
  },
  de: {
    "logout": "Ausloggen",
    "loggedInUser": "Sie sind derzeit als angemeldet ",
  },
});
const callRecording = new LocalizedStrings({
  en: {
    "recordCall": "Start call recording",
    "recordCallError1": "Can't process the operation. The are no active calls",
    "recordCallError2": "Can't process the operation. Please specify the call you want to do an action for",
    "recordCallError3": "Can't process the operation. Selected call is not active",
    "recordCallError4": "Can't process the operation. Probably this action is not supported",
    "record": "Record",
    "stop": "Stop",
    "pause": "Pause",
    "resume": "Resume",
    "noCalls": "No active calls detected. If you have one, press \"Refresh\" button.",
    "callsNoteMessage": "Note: Only calls recorded by recording service shown here.",
    "noRecordingForThisCall": "This call is not being recorded.",
  },
  sv: {
    "recordCall": "Starta samtalsinspelning",
    "recordCallError1": "Det går inte att bearbeta åtgärden. Det är inga aktiva samtal",
    "recordCallError2": "Det går inte att bearbeta åtgärden. Ange samtalet du vill göra en åtgärd för",
    "recordCallError3": "Det går inte att bearbeta åtgärden. Det valda samtalet är inte aktivt",
    "recordCallError4": "Det går inte att bearbeta åtgärden. Förmodligen stöds inte denna åtgärd",
    "record": "Spela in",
    "stop": "Sluta",
    "pause": "Paus",
    "resume": "Återuppta",
    "noCalls": "Inga pågående samtal. Om du har ett pågående samtal, tryck på \"Ladda om\" knappen.",
    "callsNoteMessage": "Obs. Endast samtal som spelas in med inspelningstjänst visas här.",
    "noRecordingForThisCall": "Detta samtal spelas inte in.",
  },
  nl: {
    "recordCall": "Start oproepopname",
    "recordCallError1": "Kan de bewerking niet verwerken. Er zijn geen actieve oproepen",
    "recordCallError2": "Kan de bewerking niet verwerken. Specificeer de oproep waarvoor u een actie wilt uitvoeren",
    "recordCallError3": "Kan de bewerking niet verwerken. Het geselecteerde gesprek is niet actief",
    "recordCallError4": "Kan de bewerking niet verwerken. Waarschijnlijk wordt deze actie niet ondersteund",
    "record": "Vermelding",
    "stop": "Hou op",
    "pause": "Pauze",
    "resume": "Hervat",
    "noCalls": "Geen actieve oproepen gevonden. Als u er een hebt, drukt u op de knop \"vernieuwen\"",
    "callsNoteMessage": "Opmerking: Alleen oproepen die zijn opgenomen door de opnameservice die hier worden weergegeven.",
    "noRecordingForThisCall": "Dit gesprek wordt niet opgenomen.",
  },
  de: {
    "recordCall": "Starten Sie die Anrufaufzeichnung",
    "recordCallError1": "Die Operation kann nicht verarbeitet werden. Das sind keine aktiven Anrufe",
    "recordCallError2": "Die Operation kann nicht verarbeitet werden. Bitte geben Sie den Anruf an, für den Sie eine Aktion ausführen möchten",
    "recordCallError3": "Die Operation kann nicht verarbeitet werden. Der ausgewählte Anruf ist nicht aktiv",
    "recordCallError4": "Die Operation kann nicht verarbeitet werden. Wahrscheinlich wird diese Aktion nicht unterstützt",
    "record": "Aufzeichnung",
    "stop": "Halt",
    "pause": "Pause",
    "resume": "Fortsetzen",
    "noCalls": "Es wurden keine aktiven Anrufe gefunden. Wenn Sie eine haben, dr?cken Sie die Taste \"Aktualisieren\".",
    "callsNoteMessage": "Hinweis: Nur Anrufe, die vom Aufzeichnungsdienst aufgezeichnet werden, werden hier angezeigt.",
    "noRecordingForThisCall": "Dieser Aufruf wird nicht aufgezeichnet.",
  },
}); 
const callTransfer = new LocalizedStrings({
  en: {
    "MOBILE": "Mobile",
    "TABLET": "Tablet",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Deskphone",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3rd party",
    "activeCalls":"Select the device to transfer the call",
  },
  sv: {
    "MOBILE": "Mobil",
    "TABLET": "Läsplatta",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Skrivbordstelefon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3: e part",
    "activeCalls":"Välj enheten för att överföra samtalet",
  },
  nl: {
    "MOBILE": "Mobiel",
    "TABLET": "Tablet",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Bureau telefoon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3e partij",
    "activeCalls":"Selecteer het apparaat om het gesprek door te verbinden",
  },
  de: {
    "MOBILE": "Mobil",
    "TABLET": "Tablette",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Tischtelefon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "Drittanbieter",
    "activeCalls":"Wählen Sie das Gerät aus, um den Anruf weiterzuleiten",
  },
}); 

//THE MUST to add new resource in the collection
const listOfResources = [
  loginForm, //
  tabPageSelector,
  acdAttendantGroups,
  supervisorGroups,
  sendSms,
  setCallerId,
  voiceMail,
  dashboard,
  settings,
  grantPermission,
  authentication,
  callRecording,
  callTransfer,
];
export {
  listOfResources,
  loginForm,
  tabPageSelector,
  acdAttendantGroups,
  supervisorGroups,
  sendSms,
  setCallerId,
  voiceMail,
  dashboard,
  settings,
  grantPermission,
  authentication,
  callRecording,
  callTransfer,
};
