import axios from 'axios';
import endpoints from '../config/endpoints';
import ResponseModel from '../models/response-model';
import AppPermissions from '../models/app-permissions';
import PresenceActivity from '../models/presence-activity';
import UserPresenceMapping from '../models/user-presence-mapping';

function getPermissions(userId: string, tenantId: string) {
  const url = `${endpoints.backEndBase}/settings/permissions?userId=${userId}&tenantId=${tenantId}`;
  return axios.get<ResponseModel<AppPermissions>>(url);
}

function getPresenceActivityList(userId: string) {
  const url = `${endpoints.backEndBase}/settings/presence?userId=${userId}`;
  return axios.get<ResponseModel<PresenceActivity[]>>(url);
}

function getPresenceActivitiesMapping(userId: string) {
  const url = `${endpoints.backEndBase}/settings/mapping?userId=${userId}`;
  return axios.get<ResponseModel<UserPresenceMapping[]>>(url);
}

function setPresenceActivitiesMapping(userId: string, body: UserPresenceMapping[]) {
  const url = `${endpoints.backEndBase}/settings/mapping?userId=${userId}`;
  return axios.post<ResponseModel<boolean>>(url, body);
}

function getPresenceActivitiesDefaultMapping(userId: string) {
  const url = `${endpoints.backEndBase}/settings/mapping/default?userId=${userId}`;
  return axios.get<ResponseModel<UserPresenceMapping[]>>(url);
}

function setPresenceActivitiesDefaultMapping(userId: string, body: UserPresenceMapping[]) {
  const url = `${endpoints.backEndBase}/settings/mapping/default?userId=${userId}`;
  return axios.post<ResponseModel<boolean>>(url, body);
}

function deleteUserSettings(userId: string) {
  const url = `${endpoints.backEndBase}/settings/mapping/reset?userId=${userId}`;
  return axios.delete<ResponseModel<boolean>>(url);
}

export {
  getPermissions,
  getPresenceActivityList,
  getPresenceActivitiesMapping,
  setPresenceActivitiesMapping,
  getPresenceActivitiesDefaultMapping,
  setPresenceActivitiesDefaultMapping,
  deleteUserSettings,
};
