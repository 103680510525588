import React from 'react';
import { Header, Flex, Button, Text, AcceptIcon, Dropdown } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import * as settingsDataActions from '../../store/actions/settings-data-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLoadingSelector, msTeamsContextSelector } from '../../store/selectors/common-selectors';
import {
  presenceActivitiesSelector,
  userPresenceDefaultMappingsSelector,
} from '../../store/selectors/settings-data-selectors';
import UserPresenceMapping from '../../models/user-presence-mapping';
import { settings } from '../../i18n/all';
import MsTeamsActivityType from '../../models/ms-teams-activity-type.enum';
import { cloneDeep } from 'lodash';
import PresenceActivity from '../../models/presence-activity';
import { isMobile } from 'react-device-detect';

interface PresenceActivityDefaultMappingProps {
  presenceActivities: PresenceActivity[];
  userPresenceDefaultMappings: UserPresenceMapping[];
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
  isLoading: boolean;
}

interface PresenceActivityDefaultMappingState {
  mappings: { [type: string]: PresenceActivity };
}

export class PresenceActivityDefaultMapping extends React.Component<
  PresenceActivityDefaultMappingProps,
  PresenceActivityDefaultMappingState
> {
  mainTypes = [
    MsTeamsActivityType.Available,
    MsTeamsActivityType.Busy,
    MsTeamsActivityType.DoNotDisturb,
    MsTeamsActivityType.BeRightBack,
    MsTeamsActivityType.Away,
    //When user select "Appear offline" Microsoft Graph sends "Offline" value as activity
    MsTeamsActivityType.OffWork,
  ];
  constructor(props: PresenceActivityDefaultMappingProps) {
    super(props);
    this.props.actions.loadPresenceActivitiesDefaultMapping(this.props.msTeamsContext?.userObjectId);
    this.state = { mappings: {} };
  }

  onSaveData() {
    var items = cloneDeep(this.props.userPresenceDefaultMappings);
    items.forEach(item => {
      var newValue = this.state.mappings[item.msTeamsPresenceActivity.toString()];
      if (newValue) {
        item.pbxPresenceActivityId = newValue.id;
        item.pbxPresenceAvailable = newValue.available;
      }
    });
    this.setState({ mappings: {} });
    this.props.actions.savePresenceActivitiesDefaultMapping(this.props.msTeamsContext?.userObjectId, items);
  }

  renderItem(m: UserPresenceMapping, allItems: PresenceActivity[]) {
    return (
      <Flex key={m.msTeamsPresenceActivity}>
        <Flex.Item size="size.quarter" styles={{ minWidth: '150px' }}>
          <Text>{settings.getString(MsTeamsActivityType[m.msTeamsPresenceActivity])}</Text>
        </Flex.Item>
        {!this.props.isLoading ? (
          <Flex.Item size="size.quarter" styles={{ minWidth: '150px' }}>
            <Dropdown
              fluid
              checkable
              defaultValue={allItems.find(pa => pa.id === m.pbxPresenceActivityId)?.name || allItems[0].name}
              items={allItems.map(a => ({
                key: a.id,
                header: a.name || '',
                item: m.msTeamsPresenceActivity.toString(),
                selected: a.id === m.pbxPresenceActivityId,
                styles: { height: '22px' },
              }))}
              onChange={(_e, data: any) => {
                var selected = allItems.find(pa => pa.id === data.value.key);
                if (selected) {
                  var mappings = this.state.mappings;
                  mappings[data.value.item] = selected;
                }
                for (var i = 0; i < data.items.length; i++) {
                  data.items[i].selected = data.items[i].key === data.value.key;
                }
              }}
            />
          </Flex.Item>
        ) : (
          <Dropdown></Dropdown>
        )}
      </Flex>
    );
  }

  render() {
    if (!this.props.presenceActivities) {
      return '';
    }
    var allItems = [
      new PresenceActivity('', false, ' ', ''), //
      ...this.props.presenceActivities,
    ];
    return (
      !!this.props.userPresenceDefaultMappings?.length && (
        <Flex column gap="gap.smaller">
          <Flex vAlign="end">
            <Flex.Item size="size.quarter" styles={{ minWidth: '150px' }}>
              <Header as="h3" content={settings.defaultMappingSettings}></Header>
            </Flex.Item>
            <Flex.Item styles={{ minWidth: '150px' }}>
              <Button
                id="save"
                icon={<AcceptIcon />}
                content={settings.save}
                iconPosition="before"
                primary
                onClick={() => this.onSaveData()}
              />
            </Flex.Item>
          </Flex>
          <Flex column gap="gap.smaller">
            <Flex>
              <Flex.Item size="size.quarter" styles={{ minWidth: '150px' }}>
                <Header as="h4" content={settings.msTeamsManualPresence}></Header>
              </Flex.Item>
              <Flex.Item size="size.quarter" styles={{ minWidth: '150px' }}>
                <Header as="h4" content={settings.pbxPresence}></Header>
              </Flex.Item>
            </Flex>
            {this.props.userPresenceDefaultMappings
              .filter(upm => this.mainTypes.findIndex(mt => upm.msTeamsPresenceActivity === mt) !== -1)
              .map(m => {
                return this.renderItem(m, allItems);
              })}
            <Header as="h4" content={settings.msTeamsAutoPresence}></Header>
            <Flex.Item size="size.quarter">
              <Text style={{ fontStyle: 'italic', maxWidth: isMobile ? '100%' : '50%' }}>
                {settings.msTeamsAutoPresenceHint}
              </Text>
            </Flex.Item>
            {this.props.userPresenceDefaultMappings
              .filter(upm => this.mainTypes.findIndex(mt => upm.msTeamsPresenceActivity === mt) === -1)
              .map(m => {
                return this.renderItem(m, allItems);
              })}
          </Flex>
        </Flex>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  presenceActivities: presenceActivitiesSelector(state),
  userPresenceDefaultMappings: userPresenceDefaultMappingsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresenceActivityDefaultMapping);
