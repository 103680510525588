import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { msTeamsContextSelector, isLoadingSelector } from '../store/selectors/common-selectors';
import * as authActions from '../store/actions/authenticate-action';
import * as commonActions from '../store/actions/common-action';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import LoginForm from '../components/configure/login-form';
import TabPageSelector from '../components/configure/tab-page-selector';
import { connect } from 'react-redux';
import { Flex } from '@fluentui/react-northstar';

interface ConfigPageProps {
  msTeamsContext: microsoftTeams.Context;
  isAuthenticated: boolean;
  isLoading: boolean;
  actions: any;
}

interface ConfigPageState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.Context | null;
  selectedPageKey: string | null;
  userObjectId: string | undefined;
}

class ConfigurePage extends Component<ConfigPageProps, ConfigPageState> {
  constructor(props: ConfigPageProps) {
    super(props);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
      selectedPageKey: null,
      userObjectId: this.props.msTeamsContext.userObjectId,
    };

    this.createTabUrl = this.createTabUrl.bind(this);
    this.onTabPageSelect = this.onTabPageSelect.bind(this);
    this.onLoginCredentialsChange = this.onLoginCredentialsChange.bind(this);
    try {
      this.registerSaveHandler();
    } catch {} // eslint-disable-line
  }

  registerSaveHandler() {
    microsoftTeams.settings.registerOnSaveHandler(saveEvent => {
      if (this.props.isAuthenticated) {
        microsoftTeams.settings.setSettings({
          contentUrl: this.createTabUrl(),
          entityId: this.createTabUrl(),
        });
        saveEvent.notifySuccess();
      } else {
        const authData = {
          email: this.state.email,
          password: this.state.password,
          userId: this.state.context?.userObjectId,
          userName: this.state.context?.userPrincipalName,
        };
        this.props.actions.doAuthentication(authData);
        microsoftTeams.settings.setValidityState(false);
      }
    });
  }
  componentDidUpdate() {}

  createTabUrl(): string {
    return `${window.location.protocol}//${window.location.host}/${this.state.selectedPageKey}?userId=${this.state.context?.userObjectId}`;
  }

  onLoginCredentialsChange(creds: { email: string; password: string }) {
    this.setState({ email: creds.email, password: creds.password }, () => {
      microsoftTeams.settings.setValidityState(!!this.state.email && !!this.state.password);
    });
  }

  onTabPageSelect(selectedPageKey: string) {
    this.setState({ selectedPageKey: selectedPageKey }, () => {
      microsoftTeams.settings.setValidityState(!!this.state.selectedPageKey);
    });
  }

  render() {
    return (
      <Flex column>
        {this.props.isAuthenticated && <TabPageSelector onSelect={this.onTabPageSelect} />}
        {this.props.isAuthenticated === false && <LoginForm onUpdateCredentials={this.onLoginCredentialsChange} />}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...commonActions,
      ...authActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurePage);
