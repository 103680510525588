import ReduxActions from '../../constants/redux-actions';
import ErrorCode from '../../models/error-code';
import { BaseAction } from '../model/action';
import Device from './../../models/device';

const defaultState = {
  devices: Array<Device>(),
  callRecordingError: ErrorCode.NONE,
};

const call = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.SET_DEVICES:
      return {
        ...state,
        devices: action.data.devices,
      };
    default:
      return state;
  }
};

export default call;
