import axios from 'axios';
import endpoints from '../config/endpoints';
import Device from '../models/device';
import ResponseModel from '../models/response-model';

function getActive(userId: string) {
  const url = `${endpoints.backEndBase}/device/active?userId=${userId}`;
  return axios.get<ResponseModel<Device[]>>(url);
}

export { getActive };
