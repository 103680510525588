import React from 'react';
import { Header, Flex, Text, Checkbox, AttendeeIcon } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import * as userDataActions from '../../store/actions/user-data-action';
import { supervisorGroupsSelector } from '../../store/selectors/user-data-selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { supervisorGroups } from '../../i18n/all';
import AcdAttendantGroupAgents from './../../models/acd-attendant-group-agents';
import Agent from '../../models/agent';

interface SupervisorGroupsProps {
  supervisorGroups: AcdAttendantGroupAgents[];
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
}

interface SupervisorGroupsState {}

export class SupervisorGroups extends React.Component<SupervisorGroupsProps, SupervisorGroupsState> {
  constructor(props: SupervisorGroupsProps) {
    super(props);
    this.props.actions.loadSupervisorGroup(this.props.msTeamsContext?.userObjectId);
  }
  switcherClick(group: AcdAttendantGroupAgents, agent: Agent) {
    this.props.actions.supervisorLoginUserToAcdAttendantGroup(this.props.msTeamsContext?.userObjectId, group, agent);
  }
  render() {
    return (
      <Flex>
        {!!this.props.supervisorGroups?.length && (
          <Flex.Item grow={true}>
            <Flex column>
              <Header as="h3" content={supervisorGroups.name}></Header>
              {this.props.supervisorGroups.map(group => {
                const loggedInAgents = group.agents.filter(a => a.isLoggedIn).length;
                const allAgents = group.agents.length;
                return (
                  <Flex key={group.id} column>
                    <Flex.Item>
                      <Header as="h4">
                        <AttendeeIcon style={{ marginRight: '10px', marginTop: '-5px' }} />
                        {`${supervisorGroups.group}: ${group.name}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {`${loggedInAgents}/${allAgents}`}
                      </Header>
                    </Flex.Item>
                    {group.agents.map(agent => {
                      return (
                        <Flex.Item key={group.id + agent.id}>
                          <Flex gap="gap.small">
                            <Flex.Item size="size.large">
                              <Text>{`${agent.firstName} ${agent.lastName}`}</Text>
                            </Flex.Item>
                            <Flex.Item size="size.medium">
                              <Text>{agent.activity}</Text>
                            </Flex.Item>
                            <Flex.Item>
                              <Checkbox
                                checked={agent.isLoggedIn}
                                toggle
                                onClick={() => this.switcherClick(group, agent)}
                              />
                            </Flex.Item>
                          </Flex>
                        </Flex.Item>
                      );
                    })}
                  </Flex>
                );
              })}
            </Flex>
          </Flex.Item>
        )}
        {this.props.supervisorGroups?.length === 0 && (
          <Flex space="between" gap="gap.smaller" vAlign="end">
            <Header as="h3" content={supervisorGroups.emptyPlaceholder}></Header>
          </Flex>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  supervisorGroups: supervisorGroupsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorGroups);
