import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';

const defaultState = {
  hasMsGraphAccess: null,
  hasSearchContactsAccess: null,
  isGlobalAdmin: null,
  isPbxAdmin: null,
  hasSmsWidgetAccess: null,
  hasCallTransferWidgetAccess: null,
  hasCallRecordingWidgetAccess: null,
  addPermissionsUrl: null,
  addSearchPermissionsUrl: null,
  userEmail: null,
  presenceActivities: null,
  userPresenceMappings: null,
  userPresenceDefaultMappings: null,
};

const settingsData = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.SET_PERMISSIONS: {
      return {
        ...state,
        // Probably later create one property for all permissions
        hasMsGraphAccess: action.data.appPermissions.hasMsGraphAccess,
        hasSearchContactsAccess: action.data.appPermissions.hasSearchContactsAccess,
        addPermissionsUrl: action.data.appPermissions.addPermissionsUrl,
        addSearchPermissionsUrl: action.data.appPermissions.addSearchPermissionsUrl,
        userEmail: action.data.appPermissions.userEmail,
        hasSmsWidgetAccess: action.data.appPermissions.hasSmsWidgetAccess,
        hasCallTransferWidgetAccess: action.data.appPermissions.hasCallTransferWidgetAccess,
        hasCallRecordingWidgetAccess: action.data.appPermissions.hasCallRecordingWidgetAccess,
        isGlobalAdmin: action.data.appPermissions.isGlobalAdmin,
        isPbxAdmin: action.data.appPermissions.isPbxAdmin,
      };
    }
    case ReduxActions.SET_PRESENCE_ACTIVITIES: {
      return {
        ...state,
        presenceActivities: action.data.presenceActivities,
      };
    }
    case ReduxActions.SET_USER_PRESENCE_MAPPINGS: {
      return {
        ...state,
        userPresenceMappings: action.data.userPresenceMappings,
      };
    }
    case ReduxActions.SET_USER_PRESENCE_DEFAULT_MAPPINGS: {
      return {
        ...state,
        userPresenceDefaultMappings: action.data.userPresenceDefaultMappings,
      };
    }
    default:
      return state;
  }
};

export default settingsData;
