import axios from 'axios';
import endpoints from '../config/endpoints';
import CallState from '../models/call-state';
import ResponseModel from '../models/response-model';

function getActive(userId: string) {
  const url = `${endpoints.backEndBase}/call/active?userId=${userId}`;
  return axios.get<ResponseModel<CallState[]>>(url);
}

function start(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/start?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function stop(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/stop?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function resume(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/resume?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function pause(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/pause?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function save(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/save?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function discard(userId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/discard?userId=${userId}${callId ? '&callId=' + callId : ''}`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

function transfer(userId: string, deviceId: string, callId?: string) {
  const url = `${endpoints.backEndBase}/call/transfer?userId=${userId}&deviceId=${encodeURIComponent(deviceId)}${
    callId ? '&callId=' + callId : ''
  }`;
  return axios.post<ResponseModel<CallState[]>>(url);
}

export {
  start, //
  stop,
  resume,
  pause,
  getActive,
  transfer,
  save,
  discard,
};
