import * as msTeams from '@microsoft/teams-js';
import * as commonActionCreators from '../action-creators/common-action-creators';
import { BaseAction } from '../model/action';
import { listOfResources } from '../../i18n/all';

const setLoadingIndicator = (isLoading: boolean) => (dispatch: (action: BaseAction) => void) => {
  dispatch(commonActionCreators.acSetLoadingIndicator(isLoading));
};

const setMsTeamsContext = (engine = msTeams) => (dispatch: any) => {
  engine.getContext((context: msTeams.Context) => {
    listOfResources.forEach(r => r.setLanguage(context.locale?.substring(0, 2) || 'en'));
    return dispatch(commonActionCreators.acSetMsTeamsContext(context));
  });
};

export { setLoadingIndicator, setMsTeamsContext };
