import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { Flex, Card, Button, FlexItem } from '@fluentui/react-northstar';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import * as authActions from '../store/actions/authenticate-action';
import { msTeamsContextSelector } from '../store/selectors/common-selectors';
import { dashboard } from '../i18n/all';
import LoginForm from '../components/configure/login-form';
import * as settingsDataActions from '../store/actions/settings-data-action';
import { isPbxAdminSelector } from '../store/selectors/settings-data-selectors';
import SupervisorGroups from '../components/org-page/supervisor-groups';
import PresenceActivityDefaultMapping from '../components/org-page/presence-activity-default-mapping';

interface OrgPageProps {
  msTeamsContext: microsoftTeams.Context;
  isAuthenticated: boolean;
  isPbxAdmin: boolean;
  actions: any;
}

interface OrgPageState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.Context | null;
}

class OrgPage extends Component<OrgPageProps, OrgPageState> {
  constructor(props: OrgPageProps) {
    super(props);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
    };
    this.props.actions.loadPermissions(this.props.msTeamsContext?.userObjectId, this.props.msTeamsContext?.tid);
  }

  render() {
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        {this.props.isAuthenticated && (
          <Flex column>
            <Flex gap="gap.small" padding="padding.medium">
              <Flex.Item grow>
                <Card>
                  <SupervisorGroups />
                </Card>
              </Flex.Item>
            </Flex>
            {!!this.props.isPbxAdmin && (
              <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                  <Card>
                    <PresenceActivityDefaultMapping />
                  </Card>
                </Flex.Item>
              </Flex>
            )}
          </Flex>
        )}
        {this.props.isAuthenticated === false && (
          <Flex
            column
            gap="gap.small"
            padding="padding.medium"
            style={{
              maxWidth: '500px',
            }}
          >
            <LoginForm
              onUpdateCredentials={creds => {
                this.setState({
                  email: creds.email,
                  password: creds.password,
                });
              }}
            />
            <Flex>
              <FlexItem push>
                <Button
                  disabled={!this.state.email || !this.state.password}
                  onClick={() => {
                    const authData = {
                      email: this.state.email,
                      password: this.state.password,
                      userId: this.state.context?.userObjectId,
                      userName: this.state.context?.userPrincipalName,
                      tenantId: this.state.context?.tid,
                    };
                    this.props.actions.doAuthentication(authData, () =>
                      this.props.actions.loadPermissions(
                        this.props.msTeamsContext?.userObjectId,
                        this.props.msTeamsContext?.tid
                      )
                    );
                  }}
                >
                  {dashboard.login}
                </Button>
              </FlexItem>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isPbxAdmin: isPbxAdminSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPage);
