import * as commonActionCreators from '../action-creators/common-action-creators';
import { BaseAction } from '../model/action';
import httpStatusCodes from '../../constants/http-status-codes';
import * as actionCreators from '../action-creators/call-action-creators';
import { start, stop, pause, resume, getActive, transfer, discard, save } from '../../api/callApi';
import { AxiosResponse } from 'axios';
import CallState from '../../models/call-state';
import ResponseModel from '../../models/response-model';
import ErrorCode from './../../models/error-code';

const getActiveCallStates = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getActive(userId);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCallStates(response.data.data));
      dispatch(actionCreators.acSetCallRecordingError(ErrorCode.NONE));
      dispatch(actionCreators.acSetCallTransferError(ErrorCode.NONE));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const transferCall = (userId: string, deviceId: string, callId?: string) => async (
  dispatch: (action: BaseAction) => void
) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await transfer(userId, deviceId, callId);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCallTransferError(response.data.errorCode));
      dispatch(actionCreators.acSetCallStates(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const startRecording = (userId: string, callId?: string) => doAction(userId, start, callId);
const stopRecording = (userId: string, callId?: string) => doAction(userId, stop, callId);
const pauseRecording = (userId: string, callId?: string) => doAction(userId, pause, callId);
const resumeRecording = (userId: string, callId?: string) => doAction(userId, resume, callId);
const saveRecording = (userId: string, callId?: string) => doAction(userId, save, callId);
const discardRecording = (userId: string, callId?: string) => doAction(userId, discard, callId);

const doAction = (
  userId: string,
  action: (userId: string, callId?: string) => Promise<AxiosResponse<ResponseModel<CallState[]>>>,
  callId?: string
) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await action(userId, callId);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCallRecordingError(response.data.errorCode));
      dispatch(actionCreators.acSetCallStates(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export {
  startRecording, //
  stopRecording,
  pauseRecording,
  resumeRecording,
  getActiveCallStates,
  transferCall,
  saveRecording,
  discardRecording,
};
