const ReduxActions = {
  RESET_STATE: 'RESET_STATE',
  AUTHENTICATE: 'AUTHENTICATE',
  LOAD_ALIASES: 'LOAD_ALIASES',
  LOAD_ACD_ATTENDANT_GROUP: 'LOAD_ACD_ATTENDANT_GROUP',
  LOAD_ACD_ATTENDANT_GROUP_AGENT: 'LOAD_ACD_ATTENDANT_GROUP_AGENT',
  REFRESH_ACD_ATTENDANT_GROUP: 'REFRESH_ACD_ATTENDANT_GROUP',
  REFRESH_AGENT_GROUP: 'REFRESH_AGENT_GROUP',
  SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
  SET_MS_TEAMS_CONTEXT: 'SET_MS_TEAMS_CONTEXT',
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  LOAD_VOICE_MAILS: 'LOAD_VOICE_MAILS',
  SET_VOICE_MAILS_DELETE_STATE: 'SET_VOICE_MAILS_DELETE_STATE',
  UPDATE_HISTORY_ITEM: 'UPDATE_HISTORY_ITEM',
  LOAD_CONTACT_LIST: 'LOAD_CONTACT_LIST',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_PRESENCE_ACTIVITIES: 'SET_PRESENCE_ACTIVITIES',
  SET_USER_PRESENCE_MAPPINGS: 'SET_USER_PRESENCE_MAPPINGS',
  SET_USER_PRESENCE_DEFAULT_MAPPINGS: 'SET_USER_PRESENCE_DEFAULT_MAPPINGS',
  SET_SEND_SMS_ERROR: 'SET_SEND_SMS_ERROR',
  SET_CALL_STATES: 'SET_CALL_STATES',
  SET_CALL_RECORDING_ERROR: 'SET_CALL_RECORDING_ERROR',
  SET_CALL_TRANSFER_ERROR: 'SET_CALL_TRANSFER_ERROR',
  SET_DEVICES: 'SET_DEVICES',
};

export default ReduxActions;
