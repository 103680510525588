import ReduxActions from '../../constants/redux-actions';
import AppPermissions from '../../models/app-permissions';
import PresenceActivity from './../../models/presence-activity';
import UserPresenceMapping from './../../models/user-presence-mapping';

function acSetPermissions(appPermissions: AppPermissions) {
  return {
    type: ReduxActions.SET_PERMISSIONS,
    data: { appPermissions: appPermissions },
  };
}

function acSetPresenceActivityList(presenceActivities: PresenceActivity[]) {
  return {
    type: ReduxActions.SET_PRESENCE_ACTIVITIES,
    data: { presenceActivities: presenceActivities },
  };
}

function acSetPresenceActivitiesMapping(userPresenceMappings: UserPresenceMapping[]) {
  return {
    type: ReduxActions.SET_USER_PRESENCE_MAPPINGS,
    data: { userPresenceMappings: userPresenceMappings },
  };
}
function acSetPresenceActivitiesDefaultMapping(userPresenceDefaultMappings: UserPresenceMapping[]) {
  return {
    type: ReduxActions.SET_USER_PRESENCE_DEFAULT_MAPPINGS,
    data: { userPresenceDefaultMappings: userPresenceDefaultMappings },
  };
}

export {
  acSetPermissions,
  acSetPresenceActivityList,
  acSetPresenceActivitiesMapping,
  acSetPresenceActivitiesDefaultMapping,
};
