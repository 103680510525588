import React from 'react';
import {
  Header,
  Flex,
  Table,
  gridNestedBehavior,
  Checkbox,
  Button,
  Text,
  SyncIcon,
  AttendeeIcon,
} from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import * as userDataActions from '../../store/actions/user-data-action';
import { acdAttendantGroupsSelector } from '../../store/selectors/user-data-selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import RoleType from '../../models/role-type.enum';
import { acdAttendantGroups } from '../../i18n/all';

interface AcdAttendantGroupsProps {
  acdAttendantGroups: AcdAttendantGroup[];
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
}

interface AcdAttendantGroupsState {}

export class AcdAttendantGroups extends React.Component<AcdAttendantGroupsProps, AcdAttendantGroupsState> {
  constructor(props: AcdAttendantGroupsProps) {
    super(props);
    this.props.actions.loadAcdAttendantGroup(this.props.msTeamsContext?.userObjectId);
  }
  handleRowClick(group: AcdAttendantGroup) {
    this.props.actions.loginUserToAcdAttendantGroup(this.props.msTeamsContext?.userObjectId, group);
  }
  render() {
    const header = {
      key: 'header',
      items: [
        { content: acdAttendantGroups.name, key: 'name', style: { fontWeight: 'bold' } },
        { content: acdAttendantGroups.loggedInAgents, key: 'numberOfLoggedInAgents', style: { fontWeight: 'bold' } },
        { content: acdAttendantGroups.isLoggedIn, key: 'isLoggedIn', style: { fontWeight: 'bold' } },
      ],
    };
    const rowsPlain = this.props.acdAttendantGroups
      ?.filter(group => group.roleList.includes(RoleType.AGENT))
      ?.map(group => {
        return {
          key: group.id,
          items: [
            { content: group.name, key: `${group.id}name`, truncateContent: true },
            {
              content: (
                <Flex gap="gap.small" vAlign="center" padding="padding.medium">
                  <AttendeeIcon />
                  <Text>{group.statistic.numberOfLoggedInAgents}</Text>
                </Flex>
              ),
              key: `${group.id}numberOfLoggedInAgents`,
            },
            {
              content: <Checkbox checked={group.isLoggedIn} toggle />,
              key: `${group.id}isLoggedIn`,
              onClick: () => this.handleRowClick(group),
            },
          ],
        };
      });
    return (
      !!rowsPlain?.length && (
        <Flex column gap="gap.smaller">
          <Flex space="between" vAlign="end">
            <Header as="h3" content={acdAttendantGroups.listOfGroups}></Header>
            <Button
              onClick={() => {
                this.props.actions.loadAcdAttendantGroup(this.props.msTeamsContext?.userObjectId);
              }}
            >
              <SyncIcon />
            </Button>
          </Flex>
          <Table
            variables={{ cellContentOverflow: 'none' }}
            header={header}
            rows={rowsPlain}
            accessibility={gridNestedBehavior}
          />
        </Flex>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  acdAttendantGroups: acdAttendantGroupsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcdAttendantGroups);
