import ReduxActions from '../../constants/redux-actions';
import Device from './../../models/device';

function acSetDevices(devices: Device[]) {
  return {
    type: ReduxActions.SET_DEVICES,
    data: { devices: devices },
  };
}

export { acSetDevices };
