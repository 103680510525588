import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const callDataRecord = (state: any) => {
  return state.call;
};

const callStatesSelector = createSelector(callDataRecord, (record = EMPTY_OBJECT) => {
  return record.callStates;
});

const callRecordingErrorSelector = createSelector(callDataRecord, (record = EMPTY_OBJECT) => {
  return record.callRecordingError;
});

const callTransferErrorSelector = createSelector(callDataRecord, (record = EMPTY_OBJECT) => {
  return record.callTransferError;
});

export { callStatesSelector, callRecordingErrorSelector, callTransferErrorSelector };
