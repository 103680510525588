import ReduxActions from '../../constants/redux-actions';
import CallState from '../../models/call-state';
import ErrorCode from '../../models/error-code';

function acSetCallStates(callStates: CallState[]) {
  return {
    type: ReduxActions.SET_CALL_STATES,
    data: { callStates: callStates },
  };
}

function acSetCallRecordingError(callRecordingError: ErrorCode) {
  return {
    type: ReduxActions.SET_CALL_RECORDING_ERROR,
    data: { callRecordingError: callRecordingError },
  };
}

function acSetCallTransferError(callTransferError: ErrorCode) {
  return {
    type: ReduxActions.SET_CALL_TRANSFER_ERROR,
    data: { callTransferError: callTransferError },
  };
}

export { acSetCallStates, acSetCallRecordingError, acSetCallTransferError };
