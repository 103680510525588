import React from 'react';
import { connect } from 'react-redux';
import { authErrorSelector } from '../../store/selectors/authentication-selectors';
import { bindActionCreators } from 'redux';
import { Input, Flex, Text, Header, ErrorIcon } from '@fluentui/react-northstar';
import ErrorCode from '../../models/error-code';
import { loginForm } from '../../i18n/all';

interface LoginFormProps {
  onUpdateCredentials: (creds: { email: string; password: string }) => void;
  authError: ErrorCode | null;
}

interface LoginFormState {
  email: string;
  password: string;
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
  }

  onEmailChanged(event: any) {
    this.setState({ email: event.target.value }, () => this.props.onUpdateCredentials({ ...this.state }));
  }

  onPasswordChanged(event: any) {
    this.setState({ password: event.target.value }, () => this.props.onUpdateCredentials({ ...this.state }));
  }
  render() {
    return (
      <Flex column gap="gap.small">
        <Header as="h3" content={loginForm.enterYourEmail} />
        <Input
          autoFocus
          required
          clearable
          fluid
          id="email"
          onChange={this.onEmailChanged}
          error={!!this.props.authError}
        />
        <Header as="h3" content={loginForm.enterYourPassword} />
        <Input
          required
          clearable
          fluid
          id="password"
          onChange={this.onPasswordChanged}
          type="password"
          error={!!this.props.authError}
        />
        {!!this.props.authError && (
          <Flex gap="gap.small" vAlign="center" padding="padding.medium">
            <ErrorIcon />
            <Text error>{loginForm.getString(ErrorCode[this.props.authError])}</Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  authError: authErrorSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      // TBD
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
