const CallActions = {
  StartRec: 'START_REC',
  StopRec: 'STOP_REC',
  SaveRec: 'SAVE_REC',
  DiscardRec: 'DISCARD_REC',
  PauseRec: 'PAUSE_REC',
  ResumeRec: 'RESUME_REC',
};

export default CallActions;
