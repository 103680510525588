import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import SetCallerId from '../components/dashboard/set-caller-id';
import { Flex, Card, Button, FlexItem } from '@fluentui/react-northstar';
import AcdAttendantGroups from './../components/dashboard/acd-attendant-groups';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import VoiceMails from '../components/dashboard/voice-mails';
import SendSms from '../components/dashboard/send-sms';
import LoginForm from '../components/configure/login-form';
import * as authActions from '../store/actions/authenticate-action';
import { msTeamsContextSelector } from '../store/selectors/common-selectors';
import { dashboard } from '../i18n/all';
import {
  hasSmsWidgetAccessSelector,
  hasCallTransferWidgetAccessSelector,
  hasCallRecordingWidgetAccessSelector,
} from '../store/selectors/settings-data-selectors';
import * as settingsDataActions from '../store/actions/settings-data-action';
import CallRecording from '../components/dashboard/call-recording';
import { isMobile } from 'react-device-detect';
import CallTransfer from '../components/dashboard/call-transfer';

interface DashboardPageProps {
  msTeamsContext: microsoftTeams.Context;
  isAuthenticated: boolean;
  hasSmsWidgetAccess: boolean;
  hasCallRecordingWidgetAccess: boolean;
  hasCallTransferWidgetAccess: boolean;
  actions: any;
}

interface DashboardPageState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.Context | null;
}

class DashboardPage extends Component<DashboardPageProps, DashboardPageState> {
  constructor(props: DashboardPageProps) {
    super(props);
    this.props.actions.loadPermissions(this.props.msTeamsContext?.userObjectId, this.props.msTeamsContext?.tid);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
    };
  }

  render() {
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        <Flex column>
          <Flex gap="gap.small" padding="padding.medium">
            <Flex.Item grow>
              <Card>
              {dashboard.deprecationNotice}
              <br/>
              <a href="https://helpcenter.soluno.se/hc/sv/articles/15047920950162-Installations-guide-Teams-Connect-app" target="_new">{dashboard.foundHere}</a>
              </Card>
            </Flex.Item>
          </Flex>
        </Flex>

        {this.props.isAuthenticated && (
          <Flex column>
            <Flex column={!!isMobile} gap="gap.small" padding="padding.medium">
              {!!this.props.hasCallRecordingWidgetAccess && (
                <Flex.Item size="size.half" styles={{ width: '100%', height: 'auto' }}>
                  <Card>
                    <CallRecording />
                  </Card>
                </Flex.Item>
              )}
              <Flex.Item size="size.half" styles={{ width: '100%', height: 'auto' }}>
                <Card>
                  <SetCallerId />
                </Card>
              </Flex.Item>
            </Flex>
            {!!this.props.hasCallTransferWidgetAccess && (
              <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                  <Card>
                    <CallTransfer />
                  </Card>
                </Flex.Item>
              </Flex>
            )}
            {/* DO NOT DELETE - will be enable back when authentication is fixed 
            {!!this.props.hasSmsWidgetAccess && (
              <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                  <Card>
                    <SendSms />
                  </Card>
                </Flex.Item>
              </Flex>
            )} */}
            <Flex gap="gap.small" padding="padding.medium">
              <Flex.Item grow>
                <Card>
                  <AcdAttendantGroups />
                </Card>
              </Flex.Item>
            </Flex>
            {/* DO NOT DELETE - will be enable back when authentication is fixed
            <Flex gap="gap.small" padding="padding.medium">
              <Flex.Item grow>
                <Card>
                  <VoiceMails />
                </Card>
              </Flex.Item>
            </Flex> */}
            {/* <Flex gap="gap.small" padding="padding.medium">
              <Flex.Item size="size.half">
                <Flex vAlign="stretch">
                  <Flex.Item grow>
                    <Card>TBD: Another dashboard block</Card>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item size="size.half">
                <Flex vAlign="stretch">
                  <Flex.Item grow>
                    <Card>TBD: Another dashboard block</Card>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </Flex>
            <Flex gap="gap.small" padding="padding.medium">
              <Flex.Item grow>
                <Card>TBD: Another dashboard block</Card>
              </Flex.Item>
            </Flex>
           */}
          </Flex>
        )}
        {this.props.isAuthenticated === false && (
          <Flex
            column
            gap="gap.small"
            padding="padding.medium"
            style={{
              maxWidth: '500px',
            }}
          >
            <LoginForm
              onUpdateCredentials={creds => {
                this.setState({
                  email: creds.email,
                  password: creds.password,
                });
              }}
            />
            <Flex>
              <FlexItem push>
                <Button
                  disabled={!this.state.email || !this.state.password}
                  onClick={() => {
                    const authData = {
                      email: this.state.email,
                      password: this.state.password,
                      userId: this.state.context?.userObjectId,
                      userName: this.state.context?.userPrincipalName,
                      tenantId: this.state.context?.tid,
                    };
                    this.props.actions.doAuthentication(authData, () =>
                      this.props.actions.loadPermissions(
                        this.props.msTeamsContext?.userObjectId,
                        this.props.msTeamsContext?.tid
                      )
                    );
                  }}
                >
                  {dashboard.login}
                </Button>
              </FlexItem>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  hasSmsWidgetAccess: hasSmsWidgetAccessSelector(state),
  hasCallTransferWidgetAccess: hasCallTransferWidgetAccessSelector(state),
  hasCallRecordingWidgetAccess: hasCallRecordingWidgetAccessSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
