import React from 'react';
import { Dropdown, DropdownProps, Header, Flex, Button, SyncIcon } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import { cloneDeep } from 'lodash';

import * as userDataActions from '../../store/actions/user-data-action';
import { userAliasesSelector } from '../../store/selectors/user-data-selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import CallerAlias from '../../models/caller-alias';
import formatAliasName from '../../helpers/alias-name-formatter';
import { setCallerId } from '../../i18n/all';

interface SetCallerIdProps {
  callerAliases: {
    availableAliases: CallerAlias[];
    currentAlias: CallerAlias;
  };
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
}

interface SetCallerIdPageState {}

export class SetCallerId extends React.Component<SetCallerIdProps, SetCallerIdPageState> {
  constructor(props: SetCallerIdProps) {
    super(props);

    this.props.actions.loadCallerAliases(this.props.msTeamsContext?.userObjectId);

    this.onSelect = this.onSelect.bind(this);
  }

  get currentAliasNumber(): number {
    return this.props.callerAliases.availableAliases.findIndex(alias => alias.active);
  }

  get aliasesPresentation(): string[] {
    return this.props.callerAliases?.availableAliases.map(a => formatAliasName(a));
  }

  onSelect(event: any, data: DropdownProps) {
    if (data.highlightedIndex === -1) {
      return;
    }

    const tempAliases = cloneDeep(this.props.callerAliases.availableAliases);
    tempAliases.forEach((alias, number) => {
      if (number === data.highlightedIndex) {
        alias.active = true;
      } else {
        alias.active = false;
      }
    });

    this.props.actions.updateCallerAliases(this.props.msTeamsContext?.userObjectId, tempAliases);
  }

  render() {
    return (
      !!this.props.callerAliases.availableAliases && (
        <Flex column gap="gap.smaller">
          <Flex space="between" vAlign="end">
            <Header as="h3" content={setCallerId.setYourCallerId}></Header>
            <Button
              onClick={() => {
                this.props.actions.loadCallerAliases(this.props.msTeamsContext?.userObjectId);
              }}
            >
              <SyncIcon />
            </Button>
          </Flex>

          <Dropdown
            fluid
            checkable
            value={formatAliasName(this.props.callerAliases.availableAliases[this.currentAliasNumber])}
            items={this.aliasesPresentation}
            onChange={this.onSelect}
          />
        </Flex>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  callerAliases: userAliasesSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetCallerId);
