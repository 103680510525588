import React from 'react';
import { Header, Box } from '@fluentui/react-northstar';
import axios from 'axios';

import endpoints from '../config/endpoints';
import httpStatusCodes from '../constants/http-status-codes';

interface TestPageState {
  responseStatus: number | string;
  isBackEndOk: boolean;
  isDbOK: boolean;
}

export class TestPage extends React.Component<{}, TestPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      responseStatus: 'loading...',
      isBackEndOk: false,
      isDbOK: false,
    };

    axios
      .get(`${endpoints.backEndBase}/test`)
      .then(response => {
        this.setState({
          responseStatus: response.status,
          isBackEndOk: response.status === httpStatusCodes.OK,
          isDbOK: response.data,
        });
      })
      .catch(error => {
        const status = error.response?.status;
        this.setState({
          responseStatus: status ? status : error.message,
          isBackEndOk: false,
          isDbOK: false,
        });
      });
  }

  render() {
    return (
      <Box>
        <Header as="h3" content="Test page " />
        <Header
          as="h5"
          content={`Back-end is ${this.state.isBackEndOk ? 'OK' : `Not OK (status: ${this.state.responseStatus})`}`}
        />
        <Header as="h5" content={`Database is ${this.state.isDbOK ? 'OK' : 'Not OK'}`} />
      </Box>
    );
  }
}
