import AliasType from '../models/alias-type.enum';

export const groupAliases = [
  AliasType.ACD, //
  AliasType.ACD_LIGHT,
  AliasType.ATTENDANT,
  AliasType.SWITCHBOARD,
];

export const aliases = [
  { key: AliasType.ANONYMOUS, value: 'Anonymous' },
  { key: AliasType.ACD, value: 'ACD' },
  { key: AliasType.ACD_LIGHT, value: 'ACD light' },
  { key: AliasType.ATTENDANT, value: 'Attendant' },
  { key: AliasType.SWITCHBOARD, value: 'Switchboard' },
  { key: AliasType.OFFICE_FIXED, value: 'Office fixed' },
  { key: AliasType.PRIVATE_FIXED, value: 'Private fixed' },
  { key: AliasType.OFFICE_MOBILE, value: 'Office mobile' },
  { key: AliasType.PRIVATE_MOBILE, value: 'Private mobile' },
];

export function getStringOfAliasType(aliasType: AliasType): string {
  const name = aliases.find(a => a.key === aliasType)?.value;
  return name ? name : AliasType[aliasType];
}
