import axios from 'axios';
import endpoints from '../config/endpoints';
import ResponseModel from '../models/response-model';

function isAuthenticated(userId: string) {
  const url = `${endpoints.backEndBase}/auth/isAuthenticated?userId=${userId}`;
  return axios.get<ResponseModel<boolean>>(url);
}

function authenticate(creds: any) {
  const body =
    `email=${creds.email}&password=${encodeURIComponent(creds.password)}` +
    `&userId=${creds.userId}&tenantId=${creds.tenantId}`;
  const url = `${endpoints.backEndBase}/auth/authenticate?${body}`;
  return axios.post<ResponseModel>(url);
}

function logout(userId: any) {
  const body = `userId=${userId}`;
  const url = `${endpoints.backEndBase}/auth/logout?${body}`;
  return axios.post<ResponseModel>(url);
}

export { isAuthenticated, authenticate, logout };
