import React from 'react';
import TeamsThemeHelper from './helpers/teams-theme-helper';
import { Provider, ThemePrepared } from '@fluentui/react-northstar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as msTeams from '@microsoft/teams-js';
import * as commonActions from './store/actions/common-action';
import * as authActions from './store/actions/authenticate-action';
import { msTeamsContextSelector, isLoadingSelector } from './store/selectors/common-selectors';
import BlockingLoader from './widgets/blocking-loader';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ConfigurePage from './pages/configure';
import DashboardPage from './pages/dashboard';
import SettingsPage from './pages/settings';
import OrgPage from './pages/org-page';
import { TestPage } from './pages/test-page';
import PlayVoiceMessagePage from './bot-pages/playVoiceMessage';
import * as settingsDataActions from './store/actions/settings-data-action';

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      theme: TeamsThemeHelper.getTheme('default'),
      loggedIn: true,
    };

    msTeams.initialize();
    msTeams.getContext(context => {
      this.updateTheme(context.theme);
    });
    msTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
    this.props.actions.setMsTeamsContext();
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.msTeamsContext && this.props.msTeamsContext !== prevProps.msTeamsContext) {
      this.props.actions.checkAuthentication(this.props.msTeamsContext.userObjectId);
    }
  }
  updateTheme(themeString: string | undefined): void {
    this.setState({
      theme: TeamsThemeHelper.getTheme(themeString),
    });
  }

  render() {
    const { msTeamsContext, isLoading } = this.props;
    return (
      <Provider theme={this.state.theme}>
        {isLoading && <BlockingLoader />}
        {
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/test" />
              </Route>
              <Route path="/test" component={TestPage} />
              {/* xxDO NOT DELETE - will be enable back when authentication is fixed
              <Route path="/voiceMail" component={PlayVoiceMessagePage} /> */}
              {msTeamsContext && <Route path="/configure" component={ConfigurePage} />}
              {msTeamsContext && <Route path="/dashboard" component={DashboardPage} />}
              {msTeamsContext && <Route path="/settings" component={SettingsPage} />}
              {msTeamsContext && <Route path="/org-page" component={OrgPage} />}
            </Switch>
          </Router>
        }
      </Provider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...commonActions,
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

interface IAppProps {
  msTeamsContext: msTeams.Context;
  isLoading: boolean;
  actions: any;
}

interface IAppState {
  theme: ThemePrepared;
  loggedIn: boolean;
}
