import axios from 'axios';
import endpoints from '../config/endpoints';
import ResponseModel from '../models/response-model';
import CallerAlias from '../models/caller-alias';
import AcdAttendantGroup from '../models/acd-attendant-group';
import Contact from '../models/contact';
import AcdAttendantGroupAgents from './../models/acd-attendant-group-agents';
import Agent from '../models/agent';

function getCallerAliases(userId: string) {
  const url = `${endpoints.backEndBase}/user/aliases?userId=${userId}`;
  return axios.get<ResponseModel<Array<CallerAlias>>>(url);
}

function updateAlias(data: any) {
  const url = `${endpoints.backEndBase}/user/aliases/update?userId=${data.userId}`;
  return axios.post(url, data);
}

function getAcdAttendantGroup(userId: string) {
  const url = `${endpoints.backEndBase}/user/acdAttendantGroup?userId=${userId}`;
  return axios.get<ResponseModel<Array<AcdAttendantGroup>>>(url);
}

function getSupervisorGroup(userId: string) {
  const url = `${endpoints.backEndBase}/user/supervisorGroupAgents?userId=${userId}`;
  return axios.get<ResponseModel<Array<AcdAttendantGroupAgents>>>(url);
}

function postLoginUserToAcdAttendantGroup(userId: string, group: AcdAttendantGroup) {
  const url = `${endpoints.backEndBase}/user/acdAttendantGroup/login?userId=${userId}`;
  const body = { group };
  return axios.post(url, body);
}

function postSupervisorLoginUserToAcdAttendantGroup(userId: string, group: AcdAttendantGroupAgents, agent: Agent) {
  const url = `${endpoints.backEndBase}/user/supervisorGroup/login?userId=${userId}`;
  const body = { group, agent };
  return axios.post(url, body);
}

function getContactList(userId: string, query: string) {
  const url = `${endpoints.backEndBase}/user/contacts/search?userId=${userId}&query=${query}`;
  return axios.get<ResponseModel<Array<Contact>>>(url);
}

function sendSms(userObjectId: string, text: string, numbers: string[], callerAlias: CallerAlias) {
  const url = `${endpoints.backEndBase}/user/sms/send?userId=${userObjectId}`;
  const data = {
    userId: userObjectId,
    text: text,
    numbers: numbers,
    callerAlias: callerAlias,
  };
  return axios.post<ResponseModel<string[]>>(url, data);
}

export {
  getCallerAliases, //
  updateAlias,
  getAcdAttendantGroup,
  getSupervisorGroup,
  postLoginUserToAcdAttendantGroup,
  postSupervisorLoginUserToAcdAttendantGroup,
  getContactList,
  sendSms,
};
