import * as commonActionCreators from '../action-creators/common-action-creators';
import { BaseAction } from '../model/action';
import httpStatusCodes from '../../constants/http-status-codes';
import * as actionCreators from '../action-creators/device-action-creators';
import { getActive } from '../../api/deviceApi';

const getActiveDevices = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getActive(userId);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetDevices(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export { getActiveDevices };
