enum MsTeamsActivityType {
  Available,
  Away,
  BeRightBack,
  Busy,
  DoNotDisturb,
  InACall,
  InAConferenceCall,
  Inactive,
  InAMeeting,
  Offline,
  OffWork,
  OutOfOffice,
  PresenceUnknown, //==Status unknown
  Presenting,
  UrgentInterruptionsOnly,
}

export default MsTeamsActivityType;
