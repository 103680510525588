import * as commonActionCreators from '../action-creators/common-action-creators';
import {
  getPermissions,
  getPresenceActivityList,
  getPresenceActivitiesMapping,
  setPresenceActivitiesMapping,
  getPresenceActivitiesDefaultMapping,
  setPresenceActivitiesDefaultMapping,
  deleteUserSettings,
} from '../../api/settingsApi';
import { BaseAction } from '../model/action';
import httpStatusCodes from '../../constants/http-status-codes';
import * as actionCreators from '../action-creators/settings-data-action-creators';
import UserPresenceMapping from '../../models/user-presence-mapping';

const loadPermissions = (userId: string, tenantId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getPermissions(userId, tenantId);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPermissions(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadPresenceActivitiesMapping = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const paListResponse = await getPresenceActivityList(userId);
    if (paListResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivityList(paListResponse.data.data));
      const paMappingResponse = await getPresenceActivitiesMapping(userId);
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesMapping(paMappingResponse.data.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const savePresenceActivitiesMapping = (userId: string, mappings: UserPresenceMapping[]) => async (
  dispatch: (action: BaseAction) => void
) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await setPresenceActivitiesMapping(userId, mappings);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivitiesMapping(mappings));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadPresenceActivitiesDefaultMapping = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const paListResponse = await getPresenceActivityList(userId);
    if (paListResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivityList(paListResponse.data.data));
      const paMappingResponse = await getPresenceActivitiesDefaultMapping(userId);
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesDefaultMapping(paMappingResponse.data.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const savePresenceActivitiesDefaultMapping = (userId: string, mappings: UserPresenceMapping[]) => async (
  dispatch: (action: BaseAction) => void
) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await setPresenceActivitiesDefaultMapping(userId, mappings);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivitiesDefaultMapping(mappings));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const resetToDefault = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await deleteUserSettings(userId);
    if (response.status === httpStatusCodes.OK) {
      const paMappingResponse = await getPresenceActivitiesMapping(userId);
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesMapping(paMappingResponse.data.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export {
  loadPermissions,
  loadPresenceActivitiesMapping,
  savePresenceActivitiesMapping,
  resetToDefault,
  loadPresenceActivitiesDefaultMapping,
  savePresenceActivitiesDefaultMapping,
};
