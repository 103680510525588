import React from 'react';
import {
  Text,
  Header,
  Flex,
  Button,
  SyncIcon,
  CallIcon,
  BroadcastIcon,
  CallEndIcon,
  UserPhoneIcon,
  PhoneArrowIcon,
  PhoneClockIcon,
  ShareToIcon,
} from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import * as callActions from '../../store/actions/call-action';
import * as deviceActions from '../../store/actions/device-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { callRecording, callTransfer } from '../../i18n/all';
import CallState from '../../models/call-state';
import ErrorCode from '../../models/error-code';
import { isMobile } from 'react-device-detect';
import {
  callStatesSelector,
  callRecordingErrorSelector,
  callTransferErrorSelector,
} from '../../store/selectors/call-selector';
import { devicesSelector } from '../../store/selectors/device-selector';
import Device from './../../models/device';
import DeviceType from './../../models/device-type.enum';

interface CallTransferProps {
  callStates: CallState[];
  callRecordingError: ErrorCode;
  callTransferError: ErrorCode;
  devices: Device[];
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
}

interface CallTransferPageState {}

export class CallTransfer extends React.Component<CallTransferProps, CallTransferPageState> {
  constructor(props: CallTransferProps) {
    super(props);

    this.props.actions.getActiveCallStates(this.props.msTeamsContext?.userObjectId);
    this.props.actions.getActiveDevices(this.props.msTeamsContext?.userObjectId);
  }
  getButtonIcon(device: Device) {
    switch (device.deviceType) {
      case DeviceType.MOBILE:
        return <CallIcon />;
      case DeviceType.SOFTPHONE:
        return <BroadcastIcon />;
      case DeviceType.TABLET:
        return <CallEndIcon />;
      case DeviceType.DESKPHONE:
        return <UserPhoneIcon />;
      case DeviceType.PBX:
        return <PhoneArrowIcon />;
      case DeviceType.THIRD_PARTY:
        return <ShareToIcon />;
      case DeviceType.DECT:
        return <PhoneClockIcon />;
      default:
        return undefined;
    }
  }
  renderButtons(call?: CallState) {
    return (
      <Flex style={{ minWidth: isMobile ? '300px' : '450px' }}>
        {this.props.devices && (
          <Button.Group
            buttons={this.props.devices.map(dev => {
              return {
                icon: this.getButtonIcon(dev),
                key: dev.deviceId,
                style: { width: '150px', padding: '0px' },
                primary: call?.deviceIds?.includes(dev.deviceId),
                content: (
                  <Flex column>
                    <Text size="small">{callTransfer.getString(DeviceType[dev.deviceType]).toString()}</Text>
                    <Text size="smaller">{dev.deviceName}</Text>
                  </Flex>
                ),
                onClick: () => {
                  this.props.actions.transferCall(this.props.msTeamsContext?.userObjectId, dev.deviceId, call?.callId);
                },
              };
            })}
          />
        )}
      </Flex>
    );
  }
  render() {
    return (
      <Flex column gap="gap.smaller">
        <Flex space="between" vAlign="end">
          <Header as="h3" content={callTransfer.activeCalls}></Header>
          <Button
            onClick={() => {
              this.props.actions.getActiveCallStates(this.props.msTeamsContext?.userObjectId);
              this.props.actions.getActiveDevices(this.props.msTeamsContext?.userObjectId);
            }}
          >
            <SyncIcon />
          </Button>
        </Flex>
        {!!this.props.callStates && this.props.callStates.length >= 2 && (
          <Flex space="between" gap={isMobile ? 'gap.large' : 'gap.small'} column>
            {this.props.callStates.map(callRec => {
              return (
                <Flex gap={isMobile ? 'gap.smaller' : 'gap.large'} key={callRec.callId} column={!!isMobile}>
                  <Flex.Item>{this.renderButtons(callRec)}</Flex.Item>
                  <Flex.Item size="size.quarter">
                    <Flex>
                      <Flex.Item size="size.half">
                        <Text>{callRec.remoteParty?.displayName}</Text>
                      </Flex.Item>
                      <Flex.Item size="size.half">
                        <Text>{callRec.remoteParty?.displayNumber}</Text>
                      </Flex.Item>
                    </Flex>
                  </Flex.Item>
                </Flex>
              );
            })}
          </Flex>
        )}
        {(!this.props.callStates || this.props.callStates.length <= 1) &&
          this.renderButtons(this.props.callStates && this.props.callStates[0])}
        {this.props.callTransferError === ErrorCode.DATA_NOT_FOUND && (
          <Text error>{callRecording.recordCallError1}</Text>
        )}
        {this.props.callTransferError === ErrorCode.INVALID_DATA && <Text error>{callRecording.recordCallError2}</Text>}
        {this.props.callTransferError === ErrorCode.UPDATE_FAIL && <Text error>{callRecording.recordCallError3}</Text>}
        {this.props.callTransferError === ErrorCode.UNKNOWN && <Text error>{callRecording.recordCallError4}</Text>}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  callStates: callStatesSelector(state),
  callRecordingError: callRecordingErrorSelector(state),
  devices: devicesSelector(state),
  callTransferError: callTransferErrorSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...callActions,
      ...deviceActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallTransfer);
