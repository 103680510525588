import * as api from '../../api/authApi';
import { BaseAction } from '../model/action';
import * as actionCreators from '../action-creators/authenticate-action-creators';
import * as commonActionCreators from '../action-creators/common-action-creators';
import ErrorCode from '../../models/error-code';

const doAuthentication = (data: any, callback: any) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    let response = await api.authenticate(data);
    dispatch(actionCreators.acAuthenticate(response.data));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      actionCreators.acAuthenticate(
        error.response
          ? error.response.data
          : {
              data: null,
              isError: true,
              errorCode: ErrorCode.UNKNOWN,
            }
      )
    );
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const removeAuthentication = (data: any, callback: any) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    await api.logout(data);
    dispatch(actionCreators.acSetIsAuthenticated(false));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const checkAuthentication = (userId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    let response = await api.isAuthenticated(userId);
    dispatch(actionCreators.acSetIsAuthenticated(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export { doAuthentication, checkAuthentication, removeAuthentication };
