import React from 'react';
import {
  Text,
  Header,
  Flex,
  Button,
  SyncIcon,
  PauseThickIcon,
  RetryIcon,
  SaveIcon,
  TrashCanIcon,
  InfoIcon,
} from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import * as callRecordingActions from '../../store/actions/call-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { callRecording, settings } from '../../i18n/all';
import CallState from '../../models/call-state';
import { callRecordingErrorSelector, callStatesSelector } from '../../store/selectors/call-selector';
import ErrorCode from '../../models/error-code';
import { isMobile } from 'react-device-detect';
import CallActions from '../../models/call-actions';

interface CallRecordingProps {
  callStates: CallState[];
  callRecordingError: ErrorCode;
  msTeamsContext: microsoftTeams.Context | null;
  actions: any;
}

interface CallRecordingPageState {}

export class CallRecording extends React.Component<CallRecordingProps, CallRecordingPageState> {
  constructor(props: CallRecordingProps) {
    super(props);

    this.props.actions.getActiveCallStates(this.props.msTeamsContext?.userObjectId);
  }

  renderButtons(call: CallState) {
    let buttons: any[];

    buttons = [
      {
        icon: <SaveIcon />,
        key: 'save',
        id: 'save',
        iconOnly: true,
        style: { width: '70px' },
        title: settings.save,
        disabled: !CallState.hasAction(call, CallActions.SaveRec),
        onClick: () => {
          this.props.actions.saveRecording(this.props.msTeamsContext?.userObjectId, call?.callId);
        },
      },
      {
        icon: <TrashCanIcon />,
        key: 'discard',
        id: 'discard',
        iconOnly: true,
        style: { width: '70px' },
        title: settings.discard,
        disabled: !CallState.hasAction(call, CallActions.DiscardRec),
        onClick: () => {
          this.props.actions.discardRecording(this.props.msTeamsContext?.userObjectId, call?.callId);
        },
      },
      {
        icon: <PauseThickIcon />,
        key: 'pause',
        id: 'pause',
        iconOnly: true,
        style: { width: '70px' },
        title: callRecording.pause,
        disabled: !CallState.hasAction(call, CallActions.PauseRec),
        onClick: () => {
          this.props.actions.pauseRecording(this.props.msTeamsContext?.userObjectId, call?.callId);
        },
      },
      {
        icon: <RetryIcon />,
        key: 'resume',
        id: 'resume',
        iconOnly: true,
        style: { width: '70px' },
        title: callRecording.resume,
        disabled: !CallState.hasAction(call, CallActions.ResumeRec),
        onClick: () => {
          this.props.actions.resumeRecording(this.props.msTeamsContext?.userObjectId, call?.callId);
        },
      },
    ];

    return (
      <Flex style={{ minWidth: '140px' }}>
        <Button.Group buttons={buttons} />
      </Flex>
    );
  }

  render() {
    const hasCalls: boolean = !!this.props.callStates && this.props.callStates.length !== 0;
    return (
      <Flex column gap="gap.smaller">
        <Flex space="between" vAlign="center">
          <Header as="h3" content={callRecording.recordCall}></Header>
          <Button
            onClick={() => {
              this.props.actions.getActiveCallStates(this.props.msTeamsContext?.userObjectId);
            }}
          >
            <SyncIcon />
          </Button>
        </Flex>

        {!hasCalls && <Text>{callRecording.noCalls}</Text>}

        {hasCalls && this.props.callStates.length >= 2 && this.renderMultipleCallButtons()}
        {hasCalls && this.props.callStates?.length === 1 && this.renderButtons(this.props.callStates[0])}

        {this.props.callRecordingError === ErrorCode.DATA_NOT_FOUND && (
          <Text error>{callRecording.recordCallError1}</Text>
        )}
        {this.props.callRecordingError === ErrorCode.INVALID_DATA && (
          <Text error>{callRecording.recordCallError2}</Text>
        )}
        {this.props.callRecordingError === ErrorCode.UPDATE_FAIL && <Text error>{callRecording.recordCallError3}</Text>}
        {this.props.callRecordingError === ErrorCode.UNKNOWN && <Text error>{callRecording.recordCallError4}</Text>}
        <div>
          <InfoIcon xSpacing="after" />
          <Text style={{ verticalAlign: 'middle' }}>{callRecording.callsNoteMessage}</Text>
        </div>
      </Flex>
    );
  }

  renderMultipleCallButtons() {
    return (
      <Flex space="between" gap={isMobile ? 'gap.large' : 'gap.small'} column>
        {this.props.callStates.map((callState: CallState) => {
          return (
            <Flex gap={isMobile ? 'gap.smaller' : 'gap.large'} key={callState.callId} column={!!isMobile}>
              <Flex.Item>
                {callState.externalRecording ? (
                  this.renderButtons(callState)
                ) : (
                  <Text error>{callRecording.noRecordingForThisCall}</Text>
                )}
              </Flex.Item>
              <Flex.Item>
                <Flex>
                  {!!callState.remoteParty?.displayName && (
                    <Flex.Item size="size.half">
                      <Text styles={{ margin: 'auto 0' }}>{callState.remoteParty?.displayName}</Text>
                    </Flex.Item>
                  )}
                  {!!callState.remoteParty?.displayNumber && (
                    <Flex.Item size="size.half">
                      <Text styles={{ margin: 'auto 0' }}>{callState.remoteParty?.displayNumber}</Text>
                    </Flex.Item>
                  )}
                </Flex>
              </Flex.Item>
            </Flex>
          );
        })}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  callStates: callStatesSelector(state),
  callRecordingError: callRecordingErrorSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...callRecordingActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallRecording);
